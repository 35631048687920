import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const Star: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  color,
  viewBox = '0 0 18 19',
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={extraClassName}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.308 6.92l-5.9.267-.408.84 4.838 3.1-1.982 6.424.63.556 5.495-4.015 5.985 4.292.475-.465-2.158-6.793L18 7.323l-.711-.803-5.476.226-2.15-5.97-.97-.354-.001.028-.017-.066L6.308 6.92z"
        fill={color}
      />
    </svg>
  );
};

export default Star;
