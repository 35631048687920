import clamp from 'lodash/clamp';

const MIN_PLACEHOLDER_WIDTH = 50;
const MAX_PLACEHOLDER_WIDTH = 90;
const STARTING_WIDTH_PERCENT = 150;

export function generatePlaceholderWidth(): string {
  return `${clamp(
    Math.floor(STARTING_WIDTH_PERCENT * Math.random()),
    MIN_PLACEHOLDER_WIDTH,
    MAX_PLACEHOLDER_WIDTH,
  )}%`;
}

export function generateLines<T>(
  number: number = Math.random() * 10,
  render: (index: number) => T,
): T[] {
  return Array(Math.floor(number || 1))
    .fill('')
    .map((_, i) => render(i));
}
