import React, {FunctionComponent} from 'react';
import cx from 'classnames';
import Link from '@pollex/components/link';
import ArrowBold from '@pollex/components/icon/components/ArrowBold';

import './BreadcrumbLink.scss';

interface BreadcrumbLinkProps {
  active?: boolean;
  href?: string;
  onClickCapture?: () => void;
  children: JSX.Element | string;
}

const BreadcrumbLink: FunctionComponent<BreadcrumbLinkProps> = ({
  active,
  href,
  onClickCapture,
  children,
}) => {
  if (href !== undefined && !active) {
    return (
      <li className="breadcrumb-link__wrapper">
        <Link
          onClickCapture={onClickCapture}
          href={href}
          className={cx('breadcrumb-link', 'breadcrumb-link_enabled')}
        >
          {children}
        </Link>
        {!active && (
          <ArrowBold
            extraClassName="breadcrumb-link__arrow"
            width="6"
            height="10"
          />
        )}
      </li>
    );
  }

  return (
    <li className="breadcrumb-link__wrapper">
      <div
        onClickCapture={onClickCapture}
        className={cx('breadcrumb-link', {
          'breadcrumb-link_active': active,
        })}
      >
        {children}
      </div>
      {!active && (
        <ArrowBold
          extraClassName="breadcrumb-link__arrow"
          width="6"
          height="10"
        />
      )}
    </li>
  );
};

export default BreadcrumbLink;
