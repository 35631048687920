import React, {FunctionComponent} from 'react';

import cx from 'classnames';
import './StaticLayoutList.scss';

interface OwnProps {
  extraClassName?: string;
  ordered?: boolean;
  withDecorations?: boolean;
}

const StaticLayoutListItem: FunctionComponent<OwnProps> = ({
  children,
  extraClassName,
}) => (
  <li className={cx('static-layout-list__item', extraClassName)}>{children}</li>
);

type StaticLayoutListType = FunctionComponent<OwnProps> & {
  Item: typeof StaticLayoutListItem;
};

const StaticLayoutList: StaticLayoutListType = ({
  ordered,
  extraClassName,
  withDecorations = true,
  children,
}) => {
  const Tag = ordered ? 'ol' : 'ul';

  return (
    <Tag
      className={cx('static-layout-list', extraClassName, {
        'static-layout-list_ordered': ordered,
        'static-layout-list_unordered': !ordered,
        'static-layout-list_unordered__no_decorations':
          !ordered && !withDecorations,
      })}
    >
      {children}
    </Tag>
  );
};

StaticLayoutList.Item = StaticLayoutListItem;

export default StaticLayoutList;
