import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const GoogleMap: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  viewBox,
}) => {
  return (
    <svg
      style={style}
      className={extraClassName}
      width={width || '19'}
      height={height || '26'}
      viewBox={viewBox || '0 0 19 26'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.94009 19.2493C5.74498 20.2543 6.56473 21.5179 6.99372 22.2812C7.51615 23.2734 7.73276 23.9434 8.1214 25.1434C8.34864 25.8007 8.56526 26 9.01973 26C9.51667 26 9.74391 25.665 9.91806 25.1434C10.2812 24.0176 10.5615 23.161 11.0054 22.3448C12.7171 19.1221 15.5076 16.8323 17.0792 13.6944C17.0792 13.6944 18.1134 11.7778 18.1134 9.09366C18.1134 6.59185 17.094 4.8533 17.094 4.8533L4.94646 19.2705L4.94009 19.2493Z"
        fill="#34A853"
      />
      <path
        d="M0.881521 13.3726C1.85561 15.5887 3.71294 17.5305 4.98073 19.24L11.7001 11.2832C11.7001 11.2832 10.7513 12.5242 9.03769 12.5242C7.12754 12.5242 5.57237 11.0046 5.57237 9.08393C5.57237 7.7627 6.36052 6.84671 6.36052 6.84671C1.41611 7.58119 1.6908 8.77789 0.86673 13.3684L0.881521 13.3726Z"
        fill="#FBBC04"
      />
      <path
        d="M11.8035 0.43338C14.038 1.15209 15.9351 2.66749 17.0735 4.88053L11.7192 11.2667C11.7192 11.2667 12.5055 10.3478 12.5055 9.0326C12.5055 7.07459 10.8528 5.59713 9.06949 5.59713C7.37679 5.59713 6.41345 6.82167 6.41345 6.82167C6.82451 5.88588 11.0721 0.751636 11.8099 0.435488L11.8035 0.43338Z"
        fill="#4285F4"
      />
      <path
        d="M2.07996 3.24718C3.42232 1.65118 5.77571 0 9.02543 0C10.5951 0 11.7866 0.415979 11.7866 0.415979L6.37043 6.84667C6.0051 6.64865 2.44104 3.87539 2.07996 3.23869V3.24718Z"
        fill="#1A73E8"
      />
      <path
        d="M0.88555 13.3466C0.88555 13.3466 0 11.6052 0 9.09813C0 6.7215 0.93864 4.63933 2.12362 3.29327L6.41333 6.86873L0.891921 13.3466H0.88555Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default GoogleMap;
