import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const ArrowTailed2: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  color,
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={extraClassName}
    >
      <path
        d="M11.657 17.657 17.314 12l-5.657-5.657M17.5 12H6"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

export default ArrowTailed2;
