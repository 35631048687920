/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  FunctionComponent,
  useEffect,
  useCallback,
  useState,
  useRef,
  Fragment,
} from 'react';

import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock-upgrade';
import capitalize from 'lodash/capitalize';

import Anchor from '@pollex/components/anchor';
import Button from '@pollex/components/button';
import MagnifyingGlass from '@pollex/components/icon/components/MagnifyingGlass';
import Arrow from '@pollex/components/icon/components/Arrow';
import ArrowBold from '@pollex/components/icon/components/ArrowBold';
import Cross from '@pollex/components/icon/components/Cross';
import plural from '@pollex/utils/plural';
import PlaceholderProgress from 'client/components/Progress/PlaceholderProgress';
import SearchMenu from 'client/components/SearchMenu';
import SearchOption from 'client/components/SearchOption';
import NB from 'client/components/NB';
import {useQuery, useLazyQuery} from '@apollo/client';
import {useLocation} from 'client/contexts/Location';
import {useAnalytics} from 'client/contexts/Analytics';
import useCombobox from 'client/hooks/use-combobox';
import useLocalStorage from '@pollex/hooks/use-local-storage';
import useId from 'client/hooks/use-id';
import debounce from 'lodash/debounce';
import cx from 'classnames';
import handsRuSearch, {
  HandsRuSearchData,
  HandsRuSearchVariables,
} from 'query-wrappers/HandsRuSearch';
import handsRuGetPopularSuggestions, {
  HandsRuGetPopularSuggestionsData,
} from 'query-wrappers/HandsRuGetPopularSuggestions';
import {toOrderCommentaries} from 'client/utils/urls';
import {LocalStorageCartFormState} from 'client/types/local-storage';
import {SearchResultType} from 'client/types/search';
import ServiceSuggestion from 'client/components/ServiceSuggestion';
import SearchInput, {SearchInputProps} from 'client/components/SearchInput';
import {
  getSuggestionGroupTitle,
  getSuggestionKey,
  getSuggestionLink,
  getSuggestionText,
  getSortedSuggestions,
  highlight,
} from './utils';
import {SLICED_LIST_LENGTH} from './constants';
import {useLastQueries} from './hooks';

import './Search.scss';

interface OwnProps {
  keepInput?: boolean;
  type: 'NAVBAR' | 'MAIN' | 'CATALOG';
  withLastQueries?: boolean;
  extraClassName?: string;
  placeholder?: string;
  icon?: JSX.Element;
}

// cant put that in utils because of circular dependency
function getSuggestionComponent(
  item: string | SearchResultType,
  inputValue: string,
) {
  if (!item) return null;
  if (typeof item === 'string') return item;
  if ('service' in item && item.service.slug === 'custom') {
    return (
      <>
        <p className="search__custom-service-heading">«{inputValue}...»</p>
        <span className="search__custom-service-notation">
          Описать своими словами
        </span>
      </>
    );
  }
  if (item.type === 'ServiceSearchResultType') {
    return <ServiceSuggestion {...item} />;
  }
  return highlight(item.suggest, item.suggestHighlightPositions, {
    color: 'var(--ruki-blue)',
  });
}

const Search: FunctionComponent<OwnProps> = ({
  keepInput,
  withLastQueries,
  type,
  extraClassName,
  placeholder = 'Услуга, проблема, предмет быта',
  icon,
}) => {
  const [isServiceListOpen, setIsServiceListOpen] = useState(false);
  const location = useLocation();
  const analytics = useAnalytics();
  const [
    cartFormStore,
    persistCartForm,
  ] = useLocalStorage<LocalStorageCartFormState>('cart-form');
  const id = useId();
  const inputRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const {
    data: popularSuggestionData,
  } = useQuery<HandsRuGetPopularSuggestionsData>(handsRuGetPopularSuggestions);

  const popularSuggestions =
    popularSuggestionData?.popularSearchSuggestions.map(s => ({
      ...s,
      isPopular: true,
    })) || [];

  const [fetchQuery, {data, loading, error}] = useLazyQuery<
    HandsRuSearchData,
    HandsRuSearchVariables
  >(handsRuSearch, {notifyOnNetworkStatusChange: true});

  const [
    lastQueries,
    addLastQuery,
    deleteOneQuery,
    clearLastQueries,
  ] = useLastQueries();

  const refetch = useCallback<(inputValue: string) => any>(
    debounce((inputValue: string) => {
      fetchQuery({variables: {inputValue}});
    }, 500),
    [],
  );

  const suggestions = data?.search ?? [];

  const [items, setItems] = useState<SearchResultType[]>([
    ...lastQueries,
    ...popularSuggestions,
  ]);

  const {
    getLabelProps,
    getInputProps,
    getItemProps,
    getMenuProps,
    selectedItem,
    inputValue,
    highlightedIndex,
    isOpen,
    isFocused,
    setInputValue,
    closeMenu,
    focusInput,
    blurInput,
    reset,
  } = useCombobox<SearchResultType>({
    id,
    items,
    itemToString: item => getSuggestionText(item, inputValue),
    onInputFocus: analytics.handleEvent('search_start', {}),
    onInputValueChange(changes) {
      setIsServiceListOpen(false);

      if (changes.inputValue && changes.inputValue !== '[object Object]') {
        refetch(changes.inputValue);
      }
    },
    onSelectedItemChange(changes) {
      if (!changes.selectedItem) {
        reset();
        blurInput();
        return;
      }

      if (changes.selectedItem.type === 'StringSearchResultType') {
        activate();
        return;
      }

      location.history.push(getSuggestionLink(changes.selectedItem, location));
    },
  });

  useEffect(() => {
    setItems(() => {
      if (!inputValue) {
        return [...lastQueries, ...popularSuggestions];
      }
      return getSortedSuggestions(suggestions, isServiceListOpen);
    });
  }, [
    inputValue,
    suggestions?.length,
    isServiceListOpen,
    lastQueries.length,
    !!popularSuggestions.length,
  ]);

  const activate = useCallback(() => {
    focusInput();

    const inputNode = inputRef.current;

    setTimeout(() => {
      if (inputNode) inputNode.focus();
    }, 0);
  }, [focusInput, inputRef.current]);

  useEffect(() => {
    if (inputValue) {
      refetch(inputValue);
    }

    const inputNode = inputRef.current;

    if (document.activeElement === inputNode && !isOpen) {
      activate();
    }
  }, []);

  useEffect(() => {
    if ((isFocused || isOpen) && menuRef.current) {
      if (type === 'CATALOG') {
        window.scrollTo({
          top:
            menuRef.current.getBoundingClientRect().top + window.scrollY - 160,
          left: 0,
          behavior: 'smooth',
        });
      }
      disableBodyScroll(menuRef.current);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [isFocused, isOpen, menuRef.current, type]);

  return (
    <>
      <div
        className={cx('search__backdrop', {
          search__backdrop_open: isFocused || isOpen,
        })}
      />
      <div
        className={cx(
          'search',
          {search_main: type === 'MAIN'},
          {
            search_focused: isFocused || isOpen,
          },
          extraClassName,
        )}
      >
        <label {...getLabelProps({})} className="search__label" title="Поиск">
          <Button
            design="plain"
            data-shmid="search-magnifying-glass-button"
            title="Поиск"
            className={cx('search__input-replacement', {
              'search__input-replacement_hidden':
                isFocused || isOpen || keepInput,
            })}
            onClick={activate}
          >
            <MagnifyingGlass color="#FFFFFF" width="32" height="32" />
          </Button>
        </label>

        <SearchInput
          {...(getInputProps({
            ref: inputRef,
            refKey: 'innerRef',
            placeholder,
            onBlur: e => {
              const {value} = e.target;
              if (typeof value !== 'string' || value.trim().length < 3) return;
              addLastQuery(value);
              analytics.handleEvent('search_abandon', {
                query: value,
              })();
            },
          }) as Partial<SearchInputProps>)}
          value={inputValue}
          onClose={() => {
            closeMenu();
            blurInput();
          }}
          actionComponent={
            <Button
              design="plain"
              data-shmid="search-reset"
              onClick={() => {
                if (type === 'CATALOG') {
                  persistCartForm({
                    ...(cartFormStore || {
                      phone: '+7',
                      name: '',
                      address: '',
                    }),
                    comment: inputValue,
                  });
                  location.history.push(
                    toOrderCommentaries({slug: 'custom'}).serialize(location),
                  );
                  return;
                }
                setInputValue('');
              }}
              className={cx('search-input__adornment', 'search-input__reset', {
                'search-input__go-to-custom': type === 'CATALOG',
              })}
            >
              <div
                className={cx(
                  'search-input__adornment-core',
                  'search-input__reset-core',
                )}
              >
                {type === 'CATALOG' ? (
                  <ArrowBold width="7" height="14" color="var(--link-color)" />
                ) : (
                  <Cross color="var(--ruki-black)" width="15px" height="15px" />
                )}
              </div>
            </Button>
          }
          data-shmid="search-input"
          isLoading={loading}
          isFocused={isFocused}
          menuIsOpen={isOpen}
          menuHasItems={Boolean(suggestions.length)}
          extraClassName={cx('search__input', {
            search__input_blurred: !isFocused && !isOpen,
            search__input_focused: isFocused || isOpen,
            search__input_keep: keepInput,
          })}
          icon={icon}
        />
        <SearchMenu
          {...getMenuProps({ref: menuRef, refKey: 'innerRef'})}
          data-shmid="search-menu"
          extraClassName={cx(
            'search__menu',
            {search__menu_main: type === 'MAIN'},
            {
              search__menu_hidden: !isOpen,
            },
          )}
        >
          <NB>
            {(() => {
              if (loading) {
                return (
                  <SearchOption readOnly>
                    <PlaceholderProgress extraClassName="search__progress" />
                  </SearchOption>
                );
              }

              if (error) {
                return (
                  <SearchOption
                    readOnly
                    onClick={() => {
                      refetch(inputValue);
                    }}
                  >
                    Ошибка.{' '}
                    <Anchor
                      component="button"
                      type="button"
                      style={{cursor: 'pointer', color: 'var(--ruki-cyan)'}}
                    >
                      Попробовать ещë раз
                    </Anchor>
                  </SearchOption>
                );
              }

              return items.map((item, i) => {
                const groupTitle = getSuggestionGroupTitle(item);
                const lastGroupTitle =
                  i > 0 && getSuggestionGroupTitle(items[i - 1]);
                return (
                  <Fragment key={getSuggestionKey(item)}>
                    {groupTitle !== lastGroupTitle &&
                      lastGroupTitle === 'Услуги' &&
                      !isServiceListOpen &&
                      suggestions.length > SLICED_LIST_LENGTH && (
                        <SearchOption
                          onClick={() => {
                            setIsServiceListOpen(true);
                            activate();
                          }}
                          extraClassName="search__fetch-more"
                          customIcon={
                            <Arrow
                              height="15px"
                              width="15px"
                              viewBox="0 0 15 15"
                              extraClassName="search__fetch-more-arrow  search-option__arrow-core"
                            />
                          }
                        >
                          Ещё {suggestions.length - SLICED_LIST_LENGTH}&nbsp;
                          {plural(
                            suggestions.length - SLICED_LIST_LENGTH,
                            'услуга',
                            'услуги',
                            'услуг',
                          )}
                        </SearchOption>
                      )}
                    {groupTitle !== lastGroupTitle && (
                      <div className="search__group-heading">
                        <div className="search__group-title">{groupTitle}</div>

                        {groupTitle === 'Недавние просмотры' && (
                          <Anchor
                            extraClassName="search__group-control"
                            component="button"
                            type="button"
                            style={{cursor: 'pointer'}}
                            onMouseDown={clearLastQueries}
                          >
                            Очистить
                          </Anchor>
                        )}
                      </div>
                    )}

                    <SearchOption
                      data-shmid="search-suggestion"
                      isActive={highlightedIndex === i}
                      customIcon={
                        groupTitle === 'Недавние просмотры' ? (
                          <Button
                            design="plain"
                            onMouseDown={e => {
                              analytics.handleEvent('last_query_click', {})();
                              e.stopPropagation();
                              deleteOneQuery(item.suggest);
                            }}
                          >
                            <Cross
                              height="15px"
                              width="15px"
                              viewBox="0 0 15 15"
                              extraClassName="search__delete-query  search-option__arrow-core"
                              color="var(--ruki-white)"
                            />
                          </Button>
                        ) : undefined
                      }
                      {...getItemProps({
                        item,
                        index: i,
                        isSelected:
                          getSuggestionKey(selectedItem) ===
                          getSuggestionKey(item),
                        onClick: _ => {
                          if (!inputValue) {
                            return;
                          }

                          const chosen = getSuggestionKey(item);

                          if (chosen === 'custom') {
                            persistCartForm({
                              ...(cartFormStore || {
                                phone: '+7',
                                name: '',
                                address: '',
                              }),
                              comment: inputValue,
                            });
                          }

                          analytics.handleEvent('search_click', {
                            query: inputValue,
                            optionIndex: i,
                            chosen,
                          })();
                        },
                      })}
                    >
                      {getSuggestionComponent(item, inputValue)}
                    </SearchOption>
                  </Fragment>
                );
              });
            })()}
          </NB>
        </SearchMenu>
      </div>

      {withLastQueries && (
        <div>
          {lastQueries.length > 0 ? (
            <div className="search__tiles">
              <p className="search__tiles-title">Недавно искали:</p>

              {lastQueries
                .map(lastQuery => getSuggestionText(lastQuery))
                .map(lastQueryText => (
                  <Button
                    key={lastQueryText}
                    design="plain"
                    className="search__tile"
                    onClick={() => {
                      setInputValue(lastQueryText);
                      activate();
                    }}
                    onClickCapture={analytics.handleEvent(
                      'last_query_click',
                      {},
                    )}
                  >
                    {capitalize(lastQueryText)}
                  </Button>
                ))}
            </div>
          ) : (
            <div className="search__tiles">
              <p className="search__tiles-title">Часто ищут:</p>
              {popularSuggestions.slice(0, 3).map((item, i) => (
                <Button
                  key={i}
                  design="plain"
                  className="search__tile"
                  onClickCapture={analytics.handleEvent(
                    'popular_suggestion_click',
                    {},
                  )}
                  onClick={
                    getItemProps({
                      item,
                      index: i,
                      isSelected:
                        getSuggestionKey(selectedItem) ===
                        getSuggestionKey(item),
                    }).onClick
                  }
                >
                  {getSuggestionText(item, inputValue)}
                </Button>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Search;
