import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const PlusHollow: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  color,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      className={extraClassName}
      fill="none"
      viewBox="0 0 46 46"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 20V.5h5v20h20v5h-20v20h-5v-20H.5v-5h20V20z"
        stroke={color}
        strokeOpacity=".1"
      />
    </svg>
  );
};

export default PlusHollow;
