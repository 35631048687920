import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const Document: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  color,
  secondaryColor,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox="0 0 23 26"
      className={extraClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.146 26L1 23.62 3.719 0 23 1.918l-2.536 21.043L15.147 26zm0-5.514l-.396 3.889 4.4-2.708-4.003-1.181z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 3.5l-.5 2L17.5 7l.5-2L5.5 3.5zM5 8l-.5 2 6.5 1 .5-2L5 8z"
        fill={secondaryColor}
      />
    </svg>
  );
};

export default Document;
