import React, {ReactNode, FunctionComponent, useState} from 'react';

import cx from 'classnames';
import Anchor from '@pollex/components/anchor';
import Link from '@pollex/components/link';
import {useLocation} from 'client/contexts/Location';
// circular dependency
import StaticLayoutTitle from 'client/components/Layouts/StaticLayout/components/StaticLayoutTitle';
import './Hub.scss';
import Button from '@pollex/components/button';
import NB from 'client/components/NB';

interface HubNode {
  label: ReactNode;
  onClickCapture?: () => any;
}

interface OwnProps {
  title: {
    href?: string;
    extraClassName?: string;
  } & HubNode;
  extraClassName?: string;
  links: ({
    href: string;
    extraClassName?: string;
  } & HubNode)[];
}

const Hub: FunctionComponent<OwnProps> = ({
  title: {label: titleLabel, ...restTitleProps},
  links,
  extraClassName,
  ...restOfProps
}: OwnProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(links.length <= 10);
  const location = useLocation();
  return (
    <div className={cx('hub', extraClassName)} {...restOfProps}>
      <div className="hub__content">
        <StaticLayoutTitle level={2} component="p" extraClassName="hub__title">
          {restTitleProps.href ? (
            <Anchor component={Link} {...restTitleProps}>
              {titleLabel}
            </Anchor>
          ) : (
            titleLabel
          )}
        </StaticLayoutTitle>
        <div className="hub__links">
          {links.map(
            (
              {label, extraClassName: linkExtraClassName, ...restLinkProps},
              i,
            ) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <div
                className={cx('hub__link', {
                  hub__link_hidden: !isOpen && i >= 10,
                  [linkExtraClassName || '']: linkExtraClassName,
                })}
                role="link"
                tabIndex={0}
                key={restLinkProps.href}
                onClick={e => {
                  e.stopPropagation();
                  location.history.push(restLinkProps.href);
                }}
                {...restLinkProps}
              >
                {label}
              </div>
            ),
          )}
        </div>

        {!isOpen && (
          <NB>
            <Button
              design="plain"
              onClick={() => setIsOpen(true)}
              extraClassName="hub__more-button"
            >
              Показать больше услуг
            </Button>
          </NB>
        )}
      </div>
    </div>
  );
};

export default Hub;
