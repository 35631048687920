export default function partition<T>(
  collection: T[],
  predicate: (element: T, index: number) => any,
): [T[], T[]] {
  // Это короче как _.partition, только умеет в индекс в предикате
  const truthy: T[] = [];
  const falsy: T[] = [];

  for (let i = 0; i < collection.length; i++) {
    const element = collection[i];
    if (predicate(element, i)) truthy.push(element);
    else falsy.push(element);
  }

  return [truthy, falsy];
}
