import React, {FunctionComponent, ReactNode, MouseEventHandler} from 'react';
import cx from 'classnames';
import Placeholder from 'client/components/Placeholder';
import './StaticLayoutTitle.scss';

interface OwnProps {
  level?: 1 | 2 | 3;
  component?: any;
  padded?: boolean;
  children: ReactNode;
  loading?: boolean;
  extraClassName?: string;
  itemProp?: string;
  itemScope?: boolean;
  onClickCapture?: MouseEventHandler;
  onClick?: MouseEventHandler;
}

const StaticLayoutTitle: FunctionComponent<OwnProps> = ({
  component,
  children,
  padded,
  loading,
  level = 3,
  extraClassName,
  ...restOfProps
}: OwnProps) => {
  const Element = component || `h${level}`;

  return (
    <Element
      className={cx(
        'static-layout-title',
        `static-layout-title_level_${level}`,
        extraClassName,
        {[`static-layout-title_level_${level}_padded`]: padded},
      )}
      {...restOfProps}
    >
      {loading ? (
        <Placeholder
          randomWidth
          extraClassName={cx(
            'static-layout-title__loader',
            `static-layout-title__loader_level_${level}`,
            {[`static-layout-title__loader_level_${level}_padded`]: padded},
          )}
        />
      ) : (
        children
      )}
    </Element>
  );
};

export default StaticLayoutTitle;
