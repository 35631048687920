import {useState, useCallback, useEffect} from 'react';

import * as storage from '@pollex/utils/safe-local-storage';

type SetItem<V> = (newItem: V) => void;

export default function useLocalStorage<V>(
  key: string,
): [V | undefined | null, SetItem<V>] {
  const [item, updateItem] = useState(storage.get(key));

  const syncState = useCallback((e: StorageEvent): void => {
    if (e.key !== key || e.newValue === item) return;
    updateItem(storage.get(key));
  }, []);

  const setItem = useCallback(
    (newValue?: V): void => {
      if (newValue === item) return;
      updateItem(newValue);
      storage.set(key, newValue);
    },
    [item],
  );

  useEffect(() => storage.listen(syncState), [item]);

  return [item, setItem];
}
