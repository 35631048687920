import React, {ForwardRefRenderFunction} from 'react';
import cx from 'classnames';
import omit from 'lodash/omit';
import ArrowBold from '../icon/components/ArrowBold';

import './anchor.scss';

interface OwnProps extends React.HTMLAttributes<HTMLAnchorElement> {
  'data-shmid'?: string;
  active?: boolean;
  component?: any;
  design?: 'default' | 'plain' | 'arrowed';
  external?: boolean;
  extraClassName?: string;
  href?: string;
  rel?: string;
  target?: string;
  themed?: boolean;
  type?: string;
}

const Anchor: ForwardRefRenderFunction<HTMLAnchorElement, OwnProps> = (
  {
    children,
    href,
    active,
    themed = true,
    design = 'default',
    extraClassName,
    component: Component = 'a',
    ...restOfProps
  },
  ref,
) => (
  <Component
    ref={ref}
    className={cx('anchor', `anchor_${design}`, extraClassName, {
      anchor_active: active,
      [`anchor_${design}_active`]: active,
      anchor_non_themed_active: !themed && active,
      anchor_non_themed: !themed,
      anchor_themed_active: themed && active,
      anchor_themed: themed,
    })}
    href={href}
    {...(Component === 'a' ? omit(restOfProps, 'external') : restOfProps)}
  >
    {children}

    {design === 'arrowed' && (
      <ArrowBold
        width="8px"
        height="13px"
        extraClassName="anchor__arrow"
        color="var(--link-color)"
      />
    )}
  </Component>
);

export default React.forwardRef(Anchor);
