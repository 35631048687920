import React, {FunctionComponent} from 'react';

import cx from 'classnames';
import {generatePlaceholderWidth} from './utils';
import './Placeholder.css';

interface OwnProps {
  extraClassName?: string;
  randomWidth?: boolean;
  animated?: boolean;
  height?: string;
  width?: string;
}

const Placeholder: FunctionComponent<OwnProps> = ({
  extraClassName = '',
  randomWidth,
  animated = true,
  height,
  width,
}) => {
  const style: Record<string, any> = {};
  const innerStyle: Record<string, any> = {};

  if (randomWidth) style.width = generatePlaceholderWidth();
  if (height) innerStyle.height = height;
  if (width) innerStyle.width = width;

  return (
    <div className={cx('placeholder', extraClassName)} style={style}>
      <div
        style={innerStyle}
        className={cx('placeholder__inner', {
          placeholder__inner_animated: animated,
          [`${extraClassName}-inner`]: extraClassName,
          [`${extraClassName}-inner_animated`]: extraClassName && animated,
        })}
      />
    </div>
  );
};

export default Placeholder;
