import React, {FunctionComponent, useEffect, useState} from 'react';
import cx from 'classnames';
import {useQuery} from '@apollo/client';
import handsRuGetCorrectCitySuggestion, {
  HandsRuGetCorrectCitySuggestionData,
  HandsRuGetCorrectCitySuggestionVariables,
} from 'query-wrappers/HandsRuGetCorrectCitySuggestion';
import {useCity} from 'client/contexts/City';
import {useMeta} from 'client/contexts/Meta';
import {useLocation} from 'client/contexts/Location';
import useMediaQuery from 'client/hooks/useMediaQuery';
import Button from '@pollex/components/button';

import './CitySpecification.scss';
import useLocalStorage from '@pollex/hooks/use-local-storage';

interface OwnProps {
  openMenu: () => void;
  isInContentBlock: boolean;
}

const CitySpecification: FunctionComponent<OwnProps> = ({
  openMenu,
  isInContentBlock,
}) => {
  const location = useLocation();
  const {current} = useCity();
  const {isMobile, botType} = useMeta();
  const isMobileView = useMediaQuery('(max-width: 600px)');
  const isOnMobileMainPage =
    isMobile && isMobileView && location.screenName === 'main';

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCurrentCityConfirmed, setIsCurrentCityConfirmed] = useLocalStorage(
    'IS_CURRENT_CITY_CONFIRMED',
  );
  const {data} = useQuery<
    HandsRuGetCorrectCitySuggestionData,
    HandsRuGetCorrectCitySuggestionVariables
  >(handsRuGetCorrectCitySuggestion, {
    variables: {cityId: current.id},
    ssr: false,
    skip:
      isCurrentCityConfirmed === current.thirdLevelDomain || botType !== 'N',
  });

  useEffect(() => {
    if (
      data &&
      data.correctCitySuggestion &&
      !data.correctCitySuggestion.isCurrentCityAccurate &&
      isCurrentCityConfirmed !== current.thirdLevelDomain
    ) {
      setIsOpen(true);
    }
  }, [data?.correctCitySuggestion?.isCurrentCityAccurate]);

  if (!isOpen) {
    return null;
  }

  // PageMainMobile renders CitySelect twice, avoid double render
  if (isOnMobileMainPage && !isInContentBlock) {
    return null;
  }

  if ('Cypress' in window) {
    return null;
  }

  return (
    <div
      className={cx('city-specification', {
        'city-specification_mobile-main-page':
          isOnMobileMainPage && isInContentBlock,
      })}
    >
      <b className="city-specification__title">Ваш город {current.name}?</b>
      <span className="city-specification__text">
        Набор услуг отличается в разных городах
      </span>

      <div className="city-specification__controls">
        <Button
          design="filled"
          size="medium"
          extraClassName="city-specification__control city-specification__control_main"
          onClick={() => {
            setIsCurrentCityConfirmed(current.thirdLevelDomain);
            setIsOpen(false);
          }}
        >
          Да, верно
        </Button>
        <Button
          design="filled"
          size="medium"
          extraClassName="city-specification__control"
          onClick={() => {
            openMenu();
            setIsOpen(false);
          }}
        >
          Нет, другой
        </Button>
      </div>
    </div>
  );
};

export default CitySpecification;
