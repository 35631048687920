import React, {FunctionComponent, ReactNode} from 'react';

import cx from 'classnames';
import PlaceholderProgress from 'client/components/Progress/PlaceholderProgress';
import './StaticLayoutBlock.scss';

type DivAttributes = React.HTMLAttributes<HTMLDivElement>;

export type BlockPadding =
  | 'bottom'
  | 'bottom-2x'
  | 'top'
  | 'top-2x'
  | '2x'
  | 'none'
  | 'left'
  | 'right'
  | 'vertical';

export type Areas =
  | 'all'
  | 'one'
  | 'two'
  | 'three'
  | 'four'
  | 'one, two'
  | 'two, three'
  | 'three, four'
  | 'one, two, three'
  | 'two, three, four';

export type Alignment =
  | ''
  | 'start'
  | 'end'
  | 'center'
  | 'stretch'
  | 'baseline';

interface CommonSettings {
  padding?: BlockPadding;
}

export interface BlockProps extends CommonSettings, DivAttributes {
  extraClassName?: string;
  loading?: boolean;
  children?: ReactNode;
  component?: any;
  align?: Alignment;
  justify?: Alignment;
  position?: '' | 'relative' | 'absolute' | 'static' | 'sticky';
  background?:
    | ''
    | 'ruki-white'
    | 'ruki-black'
    | 'ruki-blue'
    | 'ruki-cyan'
    | 'theme-default-background'
    | 'theme-accent-background';
  span?: {
    row?: 1 | 2 | 3 | 4 | 5 | 6;
    column?: 1 | 2 | 3 | 4 | 5 | 6;
  };
  areas?: Areas;
  tablet?: CommonSettings;
  mobile?: CommonSettings;
}

type OwnProps = BlockProps;

function csssify(fraction: string): string {
  return fraction.replace(/, /g, '-');
}

const StaticLayoutBlock: FunctionComponent<OwnProps> = ({
  children,
  extraClassName,
  loading,
  component: Component = 'div',
  areas = 'all',
  padding = 'bottom',
  span,
  position = '',
  align = '',
  justify = '',
  background = '',
  mobile,
  tablet,
  ...restOfProps
}: OwnProps) => {
  const t: {
    [x: string]: any;
  } = tablet || {};
  const m: {
    [x: string]: any;
  } = mobile || {};

  return (
    <Component
      className={cx(
        'static-block',
        extraClassName,
        `static-block_areas_${csssify(areas)}`,
        {
          [`static-block_background_${background}`]: background,
          [`static-block_padding_${padding}`]: padding,
          [`static-block_align_${align}`]: align,
          [`static-block_position_${position}`]: position,
          [`static-block_span_row_${span?.row}`]: span?.row,
          [`static-block_span_column_${span?.column}`]: span?.column,
          [`static-block_justify_${justify}`]: justify,
          [`static-block_tablet_padding-${t.padding}`]: t.padding,
          [`static-block_mobile_padding-${m.padding}`]: m.padding,
          'static-block_loading': loading,
        },
      )}
      {...restOfProps}
    >
      {loading && (
        <>
          <PlaceholderProgress />
          <PlaceholderProgress />
          <PlaceholderProgress />
          <PlaceholderProgress />
          <PlaceholderProgress />
          <PlaceholderProgress />
          <PlaceholderProgress />
        </>
      )}

      {children}
    </Component>
  );
};

export default StaticLayoutBlock;
