import React, {FunctionComponent} from 'react';
import Anchor from '@pollex/components/anchor';
import {useLocation} from 'client/contexts/Location';
import Link from '@pollex/components/link';
import {toClientOrders} from 'client/utils/urls';

import './ClientOrderLink.scss';

const ClientOrderLink: FunctionComponent = () => {
  const location = useLocation();

  return (
    <Anchor
      component={Link}
      href={toClientOrders().serialize(location)}
      extraClassName="client-order-link"
      title="Ваши заказы"
    >
      <div className="client-order-link__icon" />
    </Anchor>
  );
};

export default ClientOrderLink;
