import {useState, useRef, useEffect} from 'react';

export default function useMediaQuery(query: string): boolean {
  const [isMatch, setIsMatch] = useState(false);
  const queryList = useRef<MediaQueryList | null>(null);

  function updateSize(): void {
    if (queryList.current) setIsMatch(queryList.current.matches);
  }

  useEffect(() => {
    if (typeof window.matchMedia !== 'function') return;

    if (!queryList.current) {
      queryList.current = window.matchMedia(query);
      try {
        queryList.current.addEventListener('change', updateSize);
      } catch (e) {
        queryList.current.addListener(updateSize);
      }
    }

    updateSize();

    return () => {
      if (queryList.current) {
        try {
          queryList.current.removeEventListener('change', updateSize);
        } catch (e) {
          queryList.current.removeListener(updateSize);
        }
      }
    };
  }, [query]);

  return isMatch;
}
