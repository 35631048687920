import React, {
  FunctionComponent,
  // @ts-ignore
  useId,
  useState,
  useRef,
  useEffect,
} from 'react';
import cx from 'classnames';
import {useSelect} from 'downshift';
import CloseIcon from '@pollex/components/icon/components/Close';
import GeoIcon from '@pollex/components/icon/components/Geo';
import Modal from '@pollex/components/modal';
import CityDirectory from '@pollex/components/city-directory';
import CitySpecification from 'client/components/CitySpecification';
import Suggestion from 'client/components/Suggestion';
import typographize from '@pollex/utils/typographize';
// circular dependency (T_T)
import StaticLayoutWidget from 'client/components/Layouts/StaticLayout/components/StaticLayoutWidget';
import StaticLayoutBlock from 'client/components/Layouts/StaticLayout/components/StaticLayoutBlock';
import StaticLayoutTitle from 'client/components/Layouts/StaticLayout/components/StaticLayoutTitle';
import StaticLayoutLead from 'client/components/Layouts/StaticLayout/components/StaticLayoutLead';
import {useCity} from 'client/contexts/City';
import {useAnalytics} from 'client/contexts/Analytics';
import {CityType} from 'client/types/city';
import partition from '@pollex/utils/partition';
import Nobr from 'client/components/Nobr';
import NB from 'client/components/NB';
import {EventKey} from 'client/contexts/Analytics/trackers/TrackerAPI';

import './CitySelect.scss';

interface ModalProps {
  onClose: () => void;
}

const CitySelectModal: FunctionComponent<ModalProps> = ({onClose}) => {
  const {current, raw: cities, changeDomain} = useCity();
  const modalRef = useRef<HTMLDivElement>(null);
  const {handleEvent} = useAnalytics();

  useEffect(() => {
    function eventListener(e: KeyboardEvent): void {
      const letter = e.key;
      const element = window.document.getElementById(
        `list-letter-${letter.toUpperCase()}`,
      );

      if (element && modalRef.current) {
        modalRef.current.scrollTop = element.offsetTop;
      }
    }
    window.addEventListener('keypress', eventListener);

    return () => window.removeEventListener('keypress', eventListener);
  }, []);

  return (
    <Modal onClose={onClose} size="fullscreen" ref={modalRef}>
      <StaticLayoutWidget padding="none" extraClassName="city-select__modal">
        <StaticLayoutBlock>
          <StaticLayoutTitle
            level={1}
            extraClassName="city-select__modal-heading"
          >
            Выберите <Nobr>город</Nobr>
          </StaticLayoutTitle>
        </StaticLayoutBlock>
        <StaticLayoutBlock padding="top-2x">
          <StaticLayoutLead>
            В&nbsp;Москве и&nbsp;Санкт-Петербурге выполним любые работы.
            В&nbsp;остальных городах из&nbsp;списка&nbsp;&mdash; соберём,
            установим, повесим мебель.
          </StaticLayoutLead>
        </StaticLayoutBlock>
        <StaticLayoutBlock>
          <p className="city-select__table-heading">Города с&nbsp;Руками</p>
          <CityDirectory
            items={cities}
            onSelect={city => {
              handleEvent('city_select_choose_other_city', {
                city: city.thirdLevelDomain,
              })();
              changeDomain(city);
            }}
            currentId={current.id}
          />
        </StaticLayoutBlock>
      </StaticLayoutWidget>
    </Modal>
  );
};

interface OwnProps {
  citySelectButtonContent?: JSX.Element | string;
  withCloseButton?: boolean;
  extraClassName?: string;
  onChange?: (city: CityType) => void;
  showSpecification?: boolean;
  shouldUsePrepositionalName?: boolean;
  cities?: CityType[];

  onToggleButtonClickEventKey?: EventKey;
  onCityChangeEventKey?: EventKey;
}

const CitySelect: FunctionComponent<OwnProps> = ({
  citySelectButtonContent,
  withCloseButton = false,
  extraClassName,
  showSpecification = true,
  shouldUsePrepositionalName,
  cities: citiesFromProps,
  onChange,
  onToggleButtonClickEventKey = 'city_selector_click',
  onCityChangeEventKey = 'city_selector_choose_city',
}: OwnProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {current, raw, changeDomain} = useCity();
  const {handleEvent} = useAnalytics();
  const selectId = useId();

  const cities = citiesFromProps || raw;

  const [open, underConstruction] = partition(
    cities,
    ({id, components}) =>
      components.some(c => c.name === 'B2C_SELECTOR') || id === current.id,
  );

  const {
    getMenuProps,
    getItemProps,
    getToggleButtonProps,
    isOpen,
    closeMenu,
    openMenu,
    highlightedIndex,
    selectedItem,
  } = useSelect<CityType>({
    id: selectId,
    items: open,
    itemToString: item => item?.name ?? '',
    onSelectedItemChange({selectedItem: nextItem}) {
      if (!nextItem) return;
      if (onChange) {
        onChange(nextItem);
      } else {
        changeDomain(nextItem);
      }

      handleEvent(onCityChangeEventKey, {
        city: nextItem.thirdLevelDomain || 'msk',
      })();
    },
  });

  const currentCity = selectedItem || current;

  return (
    <div className="city-select__wrapper">
      <button
        title="Выбор города"
        type="button"
        className={cx('city-select__button', {
          'city-select__button_opened': isOpen,
          [`${extraClassName}`]: extraClassName,
        })}
        data-shmid="city-selector"
        onClickCapture={handleEvent(onToggleButtonClickEventKey, {})}
        {...getToggleButtonProps()}
      >
        {citySelectButtonContent || <GeoIcon color="var(--ruki-white)" />}
        <div className="city-select__current-city">
          {shouldUsePrepositionalName
            ? currentCity.prepositional
            : currentCity.name}
        </div>
      </button>

      {showSpecification && (
        <CitySpecification
          openMenu={openMenu}
          isInContentBlock={!!citySelectButtonContent}
        />
      )}

      <NB>
        <div
          {...getMenuProps()}
          className={cx('city-select__list', {
            'city-select__list_open': isOpen,
          })}
        >
          {withCloseButton && (
            <button
              type="button"
              className="city-select__list_close-button"
              onClick={closeMenu}
            >
              <CloseIcon color="var(--ruki-blue)" width="20px" height="20px" />
            </button>
          )}

          {open.map((city, i: number) => (
            <Suggestion
              {...getItemProps({
                refKey: 'innerRef',
                index: i,
                item: city,
                key: city.id,
              })}
              key={city.id}
              highlighted={highlightedIndex === i}
              extraClassName={cx('city-select__list-item', {
                'city-select__list-item_current': currentCity.id === city.id,
              })}
            >
              {typographize(city.name)}
            </Suggestion>
          ))}

          {!!underConstruction.length && (
            <Suggestion
              {...getItemProps({
                refKey: 'innerRef',
                // @ts-ignore
                item: {id: 'extra'},
                index: open.length,
                key: 'extra',
              })}
              {...{
                onClick: () => {
                  setIsModalOpen(true);
                  handleEvent('city_selector_all_city_button_click', {})();
                },
              }}
              highlighted={false}
              extraClassName="city-select__list-item"
            >
              Другой город…
            </Suggestion>
          )}
        </div>
      </NB>

      {isModalOpen && <CitySelectModal onClose={() => setIsModalOpen(false)} />}
    </div>
  );
};

export default CitySelect;
