import {useCallback, useEffect, useState} from 'react';
import {
  useCombobox as _useCombobox,
  UseComboboxProps,
  UseComboboxReturnValue,
  UseComboboxState,
} from 'downshift';

interface ExtendedUseComboboxReturnValue<Item>
  extends UseComboboxReturnValue<Item> {
  focusInput: () => any;
  blurInput: () => any;
  isFocused: boolean;
}

export type ComboboxChanges<Item> = Partial<UseComboboxState<Item>>;

interface ExtendedUseComboboxProps<Item> extends UseComboboxProps<Item> {
  onInputFocus?: () => any;
  onInputBlur?: () => any;
}

export default function useCombobox<Item>({
  onInputFocus,
  onInputBlur,
  ...comboboxProps
}: ExtendedUseComboboxProps<Item>): ExtendedUseComboboxReturnValue<Item> {
  /*
    This component extends default useCombobox from downshift library
    with values and capabilities of focusing elements by introducing
    focusInput(), blurInput() and isFocused into it's return values.

    Also, this component makes underlying implementation of downshift's
    combobox controllable over one of it's state variables: isOpen when
    setting it explicitly in the return value. Be careful.
  */
  const {getInputProps, reset, ...restOfBag} = _useCombobox<Item>(
    comboboxProps,
  );
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isFocused && onInputFocus) {
      onInputFocus();
    }
    if (!isFocused && onInputBlur) {
      onInputBlur();
    }
  }, [isFocused]);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
    setIsOpen(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
    setIsOpen(false);
  }, []);

  return {
    ...restOfBag,
    reset() {
      setIsOpen(false);
      reset();
    },
    focusInput: handleFocus,
    blurInput: handleBlur,
    isFocused,
    isOpen,

    getInputProps(base) {
      const baseProps = getInputProps(base);
      return {
        ...baseProps,
        onFocus() {
          handleFocus();
        },
        onBlur(e) {
          // @ts-ignore
          if (baseProps.onBlur) baseProps.onBlur(e);
          handleBlur();
        },
      };
    },
    openMenu() {
      setIsOpen(true);
    },
    closeMenu() {
      setIsOpen(false);
      handleBlur();
    },
  };
}

useCombobox.stateChangeTypes = _useCombobox.stateChangeTypes;
