import React, {FunctionComponent, ReactNode} from 'react';

import cx from 'classnames';
import {FourSideType} from 'client/types/side';
import StaticLayoutBlock, {BlockProps} from '../StaticLayoutBlock';
import './StaticLayoutBleeder.scss';

interface OwnProps extends BlockProps {
  extraClassName?: string;
  children: ReactNode;
  sides: FourSideType[];
}

const StaticLayoutBleeder: FunctionComponent<OwnProps> = ({
  sides = ['top', 'right', 'bottom', 'left'],
  extraClassName,
  children,
  ...restOfProps
}: OwnProps) => (
  <StaticLayoutBlock
    padding="none"
    extraClassName={cx(
      'static-layout-bleeder',
      ...sides.map(side => `static-layout-bleeder_${side}`),
      extraClassName,
    )}
    {...restOfProps}
  >
    {children}
  </StaticLayoutBlock>
);

export default StaticLayoutBleeder;
