import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const Telegram: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '52'}
      height={height || '52'}
      fill="none"
      className={extraClassName}
      viewBox="0 0 52 52"
    >
      <path
        fill="url(#a)"
        d="M26 52c14.36 0 26-11.64 26-26S40.36 0 26 0 0 11.64 0 26s11.64 26 26 26Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11.755 25.718c7.577-3.29 12.621-5.477 15.154-6.538 7.21-3.009 8.725-3.528 9.699-3.55.216 0 .693.043 1.017.303.26.216.325.498.368.714.044.217.087.672.044 1.018-.39 4.113-2.078 14.093-2.944 18.683-.369 1.948-1.083 2.598-1.776 2.662-1.515.13-2.662-.995-4.113-1.948-2.295-1.494-3.572-2.425-5.802-3.897-2.576-1.688-.909-2.62.563-4.135.39-.39 7.036-6.45 7.166-6.992.021-.065.021-.325-.13-.455-.152-.13-.368-.086-.541-.043-.238.043-3.875 2.468-10.954 7.252-1.04.715-1.97 1.061-2.815 1.04-.93-.022-2.706-.52-4.048-.953-1.624-.52-2.923-.801-2.814-1.71.065-.477.714-.953 1.926-1.45Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="a"
          x1="25.978"
          x2="25.978"
          y1="0"
          y2="51.589"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2AABEE" />
          <stop offset="1" stopColor="#229ED9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Telegram;
