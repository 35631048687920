import handsRuGetCorrectCitySuggestion from 'queries/handsRuGetCorrectCitySuggestion.graphql';

export interface HandsRuGetCorrectCitySuggestionData {
  correctCitySuggestion: {
    isCurrentCityAccurate: boolean;
    suggestedCity?: {
      thirdLevelDomain: string;
    };
  };
}

export interface HandsRuGetCorrectCitySuggestionVariables {
  cityId: string;
}

export default handsRuGetCorrectCitySuggestion;
