import React, {FunctionComponent} from 'react';
import cx from 'classnames';

import {useLocation} from 'client/contexts/Location';

import {
  toDocuments,
  toVacancies,
  toRetail,
  toCompanyAbout,
  toCompanyContacts,
  toClassifier,
  toSpecialistRegistration,
  toAllReviewPage,
} from 'client/utils/urls';

import FooterLink from '../Link';
import './Links.scss';

interface OwnProps {
  themed?: boolean;
  isMain?: boolean;
}

const Links: FunctionComponent<OwnProps> = ({themed, isMain}) => {
  const location = useLocation();

  return (
    <div className={cx('footer-links', {'footer-links_main': isMain})}>
      <div className="footer-links__column">
        <FooterLink themed={themed} href={toClassifier().serialize(location)}>
          Все услуги
        </FooterLink>

        <FooterLink themed={themed} href={toCompanyAbout().serialize(location)}>
          О сервисе
        </FooterLink>

        <FooterLink
          themed={themed}
          href={toAllReviewPage().serialize(location)}
        >
          Отзывы
        </FooterLink>
      </div>

      <div className="footer-links__column">
        <FooterLink themed={themed} href={toDocuments().serialize(location)}>
          Документы
        </FooterLink>
        <FooterLink themed={themed} href={toVacancies().serialize(location)}>
          Вакансии
        </FooterLink>
        <FooterLink
          themed={themed}
          href={toCompanyContacts().serialize(location)}
        >
          Контакты
        </FooterLink>
      </div>

      <div className="footer-links__column">
        <FooterLink
          themed={themed}
          href={toSpecialistRegistration().serialize(location)}
        >
          Стать мастером
        </FooterLink>

        <FooterLink
          external
          themed={themed}
          href={toRetail().serialize(location)}
        >
          Ритейлерам
        </FooterLink>
      </div>
    </div>
  );
};

export default Links;
