import React, {CSSProperties} from 'react';
import partition from 'lodash/partition';
import {
  toObject,
  toCategory,
  toService,
  toOrderCommentaries,
} from 'client/utils/urls';
import SerializableURL from 'client/utils/serializable-url';
import {LocationBag} from 'client/contexts/Location';
import {SearchResultType, TextRange} from 'client/types/search';
import {SLICED_LIST_LENGTH} from './constants';

export function highlight(
  suggest: string,
  highlightPositions: TextRange[] | undefined,
  style: CSSProperties,
): JSX.Element {
  if (!highlightPositions?.length) return <>{suggest}</>;
  const sliced: string[] = [...highlightPositions]
    .sort((a, b) => a.start - b.start)
    .reduce((s, position, i, positions) => {
      const prevPosition = i === 0 ? 0 : positions[i - 1].end;
      return [
        ...s,
        suggest.slice(prevPosition, position.start),
        suggest.slice(position.start, position.end),
      ];
    }, [] as string[]);

  sliced.push(
    suggest.slice(highlightPositions[highlightPositions.length - 1].end),
  );

  return (
    <div>
      {sliced.map((s, i) =>
        i % 2 !== 0 ? (
          <span key={i} style={style}>
            {s}
          </span>
        ) : (
          s
        ),
      )}
    </div>
  );
}

export function getSuggestionGroupTitle(suggest: SearchResultType | string) {
  if (typeof suggest === 'string') return 'Недавние просмотры';
  if (suggest.isPopular) return 'Популярные';
  if ('service' in suggest && suggest.service.slug === 'custom') {
    return 'Своими словами';
  }
  if (suggest.type === 'ServiceSearchResultType') {
    return 'Услуги';
  }
  return 'Другое';
}

export function getSuggestionKey(item?: SearchResultType | null): string {
  if (!item) return '';

  switch (item.type) {
    case 'ServiceSearchResultType': {
      return item.service.slug;
    }

    case 'ObjectSearchResultType': {
      return item.object.slug;
    }

    case 'CategorySearchResultType': {
      return item.category.slug;
    }

    case 'LinkSearchResultType': {
      return item.link;
    }

    case 'StringSearchResultType': {
      return item.suggest;
    }

    default: {
      throw new Error('Unexpected search result type');
    }
  }
}

export function getSuggestionText(
  item?: SearchResultType | null,
  inputValue?: string,
): string {
  if (!item) {
    return '';
  }

  if (
    item.type === 'ServiceSearchResultType' &&
    item.service.slug === 'custom' &&
    inputValue
  ) {
    return inputValue;
  }

  return item.suggest;
}

export function getSuggestionLink(
  item: SearchResultType,
  location: LocationBag,
): string {
  if (!item) return '';

  switch (item.type) {
    case 'ServiceSearchResultType': {
      if (item.service.slug === 'custom') {
        return toOrderCommentaries(
          item.service,
          item.service.defaultCategory,
          true,
        ).serialize(location);
      }
      return toService(item.service, {
        category: item.service.defaultCategory,
      }).serialize(location);
    }

    case 'ObjectSearchResultType': {
      return toObject(item.object, item.object.defaultCategory).serialize(
        location,
      );
    }

    case 'CategorySearchResultType': {
      return toCategory(item.category).serialize(location);
    }

    case 'LinkSearchResultType': {
      return new SerializableURL({base: item.link}).serialize(location);
    }

    default: {
      throw new Error('Unexpected search result type');
    }
  }
}

const CUSTOM_SERVICE: SearchResultType = {
  type: 'ServiceSearchResultType',
  suggest: 'Описать задачу своими словами',
  suggestHighlightPositions: [],
  service: {
    slug: 'custom',
    defaultCategory: {
      slug: 'santekhnika',
    },
    price: {
      amount: 0,
      unit: 'FIX',
      unitMorphology: '',
    },
    minimumPrice: {
      amount: 0,
      unit: 'FIX',
      unitMorphology: '',
    },
  },
};

export function getSortedSuggestions(
  suggestions: SearchResultType[],
  isServiceListOpen: boolean,
): SearchResultType[] {
  const [serviceSuggestions, otherSuggestions] = partition(
    suggestions,
    s => s.type === 'ServiceSearchResultType',
  );

  const slicedServiceSuggestions = isServiceListOpen
    ? serviceSuggestions
    : serviceSuggestions.slice(
        0,
        Math.max(SLICED_LIST_LENGTH - otherSuggestions.length, 3),
      );

  return [...slicedServiceSuggestions, ...otherSuggestions, CUSTOM_SERVICE];
}
