import React, {FunctionComponent, RefObject} from 'react';
import cx from 'classnames';

import './Suggestion.css';

interface OwnProps {
  highlighted: boolean;
  extraClassName?: string;
  innerRef?: RefObject<HTMLDivElement>;
}

const Suggestion: FunctionComponent<OwnProps> = ({
  children,
  highlighted,
  extraClassName,
  innerRef,
  ...restOfProps
}) => (
  <div
    ref={innerRef}
    className={cx('suggestion', extraClassName, {
      suggestion_highlighted: highlighted,
    })}
    {...restOfProps}
  >
    {children}
  </div>
);

export default Suggestion;
