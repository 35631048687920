import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const PointingHand: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  color,
}) => {
  return (
    <svg
      width={width || '48'}
      height={height || '33'}
      className={extraClassName}
      viewBox="0 0 48 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m48 12.6344-.7343 4.3361-18.1378.5763 3.488 1.4769-.9179 3.1699-3.5982 1.1887 2.974 1.0806-1.028 3.6022-3.1943 1.1166 2.6802.8285L28.6873 33l-18.3214-1.5129H0V17.2947h10.4394L19.545 0l3.488 2.0172-3.9286 10.6172H48Z"
        fill={color}
      />
    </svg>
  );
};

export default PointingHand;
