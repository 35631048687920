import React, {FunctionComponent} from 'react';

import cx from 'classnames';
import {AlignmentType} from 'client/types/side';
import './StaticLayoutImage.css';

interface OwnProps {
  height: string;
  width: string;
  src: string;
  alt: string;
  justify?: AlignmentType;
  align?: AlignmentType;
  backgroundSize?: string;
  extraClassName?: string;
}

const StaticLayoutImage: FunctionComponent<OwnProps> = ({
  width = 'auto',
  height = 'auto',
  src,
  alt,
  justify = 'center',
  align = 'center',
  backgroundSize = 'auto auto',
  extraClassName,
  ...restOfProps
}: OwnProps) => (
  <div
    className={cx(
      'static-layout-image',
      extraClassName,
      `static-layout-image_justify_${justify}`,
      `static-layout-image_align_${align}`,
    )}
    {...restOfProps}
    style={{
      maxHeight: height,
      maxWidth: width,
      backgroundImage: `url(${src})`,
      backgroundSize,
    }}
  >
    <img
      src={src}
      alt={alt}
      className="static-layout-image__inner"
      height={height}
      width={width}
    />
  </div>
);

export default StaticLayoutImage;
