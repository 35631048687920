import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const ShieldCheckmark: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  color,
  viewBox,
  secondaryColor,
}) => {
  return (
    <svg
      style={style}
      width={width || '46'}
      height={height || '55'}
      viewBox={viewBox || '0 0 46 55'}
      fill={secondaryColor}
      className={extraClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8061 0C22.8061 0 7.45819 3.35893 0.0121775 4.79847C-0.367721 31.2668 8.161 46.3148 22.8061 53.7429C35.3047 48.7332 45.0681 38.1766 45.6 4.79847C37.8501 3.30135 22.8061 0 22.8061 0ZM21.8563 36.4684L11.4091 24.952L14.2584 22.073L21.8563 30.7102L36.1025 13.4357L38.9518 16.3148L21.8563 36.4684Z"
        transform="translate(0 0.628571)"
        fill={color}
      />
    </svg>
  );
};

export default ShieldCheckmark;
