import React, {FunctionComponent, ReactNode} from 'react';
import SheetWithHands from 'client/components/SheetWithHands';
import CatalogPageCoverImage from 'client/components/CatalogPageCoverImage';
import StaticLayoutBlock from '../StaticLayoutBlock';
import StaticLayoutWidget, {WidgetProps} from '../StaticLayoutWidget';

import './StaticLayoutSheetWidget.scss';

interface OwnProps extends WidgetProps {
  title: ReactNode;
}

const StaticLayoutSheetWidget: FunctionComponent<OwnProps> = ({
  children,
  title,
  ...restOfProps
}) => (
  <StaticLayoutWidget
    padding="horizontal"
    background="theme-default-background"
    extraClassName="static-layout-sheet-widget"
    contentProps={{extraClassName: 'static-layout-sheet-widget__content'}}
    {...restOfProps}
  >
    <CatalogPageCoverImage position="BOTTOM_LEFT" />
    <CatalogPageCoverImage position="BOTTOM_RIGHT" />
    <CatalogPageCoverImage position="TOP_LEFT" />
    <CatalogPageCoverImage position="TOP_RIGHT" />
    <StaticLayoutBlock padding="none">
      <div className="static-layout-sheet-widget__title">{title}</div>
      <SheetWithHands>{children}</SheetWithHands>
    </StaticLayoutBlock>
  </StaticLayoutWidget>
);

export default StaticLayoutSheetWidget;
