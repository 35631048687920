import React, {FunctionComponent} from 'react';

import './Logo.scss';

const Logo: FunctionComponent = () => (
  <div className="navbar-logo">
    <div className="navbar-logo__img" />
  </div>
);

export default Logo;
