import handsRuGetCatalogObjectPage from 'queries/handsRuGetCatalogObjectPage_4.graphql';
import {
  GeoUnitSlugType,
  ObjectSlugType,
  ObjectPageSlugType,
  CategorySlugType,
  ServicePageSlugType,
} from 'client/types/slug';
import {PriceType} from 'client/types/price';
import {Image} from 'client/types/image';
import {FrequentlyAskedQuestion} from 'client/types/frequently-asked-question';

export interface HandsRuGetCatalogObjectPageData {
  catalog: {
    priceListPage: {id: string} | null;
    isPageGone: boolean;

    objectPage: {
      object: {
        name: string;
        slug: ObjectSlugType;
        description: string;
        isVisible: boolean;
        defaultCategory: {
          slug: CategorySlugType;
          name: string;
        };
      };
      mainServicePages: {
        name: string;
        slug: ServicePageSlugType;
        service: {
          type: 'ServiceType';
          h1: string;
          price: PriceType;
          minimumPrice: PriceType;
        };
        photosOfSpecialists: Image[];
      }[];
      satelliteServicePages: {
        name: string;
        slug: ServicePageSlugType;
        service: {
          type: 'ServiceType';
          h1: string;
          price: PriceType;
          minimumPrice: PriceType;
        };
        photosOfSpecialists: Image[];
      }[];
      frequentlyAskedQuestions: FrequentlyAskedQuestion[];

      name: string;
      nameTo: string;
      h1: string;
      reviewsH2: string;
      pageLinksH2: string;
      tags: string[];
      updatedAt: string;
    };
  };
}

export interface HandsRuGetCatalogObjectPageVariables {
  slug: ObjectPageSlugType;
  geo: GeoUnitSlugType;
}

export default handsRuGetCatalogObjectPage;
