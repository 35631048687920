import qs from 'qs';
import {LocationQueryType} from 'client/contexts/Location';

export function urlParamGenerator(baseUrl: string, query: LocationQueryType) {
  const params: Record<string, string> = {};
  if (query.utm_campaign) {
    params.c = query.utm_campaign;
  }
  if (query.utm_source) {
    params.af_channel = query.utm_source;
  }
  if (query.utm_term) {
    params.af_ad = query.utm_term;
  }
  if (query.utm_medium) {
    params.af_ad_type = query.utm_medium;
  }
  const stringifiedParams = qs.stringify(params);
  if (!stringifiedParams) {
    return baseUrl;
  }
  return `${baseUrl}/?${stringifiedParams}`;
}
