import React, {ReactNode, FunctionComponent} from 'react';

import cx from 'classnames';
import {VerticalSideType} from 'client/types/side';
import './StaticLayoutLead.scss';

interface OwnProps {
  children: ReactNode;
  extraClassName?: string;
  component?: any;
  number?: number;
  padding?: VerticalSideType | 'none';
}

const StaticLayoutLead: FunctionComponent<OwnProps> = ({
  children,
  extraClassName,
  number,
  padding = 'bottom',
  component: Component = 'div',
}: OwnProps) => (
  <Component
    className={cx(
      'static-layout-lead',
      `static-layout-lead_padding_${padding}`,
      extraClassName,
      {
        'static-layout-lead_with_number': number,
      },
    )}
  >
    {number && <div className="static-layout-lead__number">{number}.</div>}

    {children}
  </Component>
);

export default StaticLayoutLead;
