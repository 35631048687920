import React, {FunctionComponent, useState} from 'react';

import cx from 'classnames';
import {useQuery} from '@apollo/client';
import {useAnalytics} from 'client/contexts/Analytics';
import {useLocation} from 'client/contexts/Location';
import {BotType, useMeta} from 'client/contexts/Meta';
import Link from '@pollex/components/link';
import phoneNumberFormatter from '@pollex/utils/normalize-user-input-phone';
import Button from '@pollex/components/button';
import useMediaQuery from 'client/hooks/useMediaQuery';
import handsRuGetCallCenterPhone, {
  HandsRuGetCallCenterPhoneData,
} from 'query-wrappers/HandsRuGetCallCenterPhone';

import './PhoneNumber.scss';

interface OwnProps {
  color: 'ruki-white' | 'ruki-black';
  raw?: boolean;
  openByDefault?: boolean;
}

function getNumberByMeta(
  botType: BotType,
  referrer: string = '',
  query: Record<string, string>,
  realNumber: string | undefined,
) {
  const COMMERCIAL_NUMBER = '+78003027056';
  if (botType === 'G' || botType === 'Y') {
    return COMMERCIAL_NUMBER;
  }

  if (referrer.includes('google')) {
    return COMMERCIAL_NUMBER;
  }

  if (
    (referrer.includes('yandex.ru') || referrer.includes('ya.ru')) &&
    !Object.keys(query).some(param => param.includes('_utm'))
  ) {
    return COMMERCIAL_NUMBER;
  }

  return realNumber;
}

const PhoneNumber: FunctionComponent<OwnProps> = ({
  color,
  raw,
  openByDefault = false,
}) => {
  const {query} = useLocation();
  const {handleEvent} = useAnalytics();
  const {botType, referrer} = useMeta();
  const isBot = botType === 'G' || botType === 'Y';
  const [shouldShowNumber, setShouldShowNumber] = useState<boolean>(
    openByDefault || isBot,
  );
  const isMobile = useMediaQuery('(max-width: 600px)');
  const {data} = useQuery<HandsRuGetCallCenterPhoneData>(
    handsRuGetCallCenterPhone,
    {
      ssr: false,
      fetchPolicy: 'network-only',
      skip: isBot,
    },
  );

  if (query.utm_source === 'leadssu') {
    return null;
  }

  const number = getNumberByMeta(
    botType,
    referrer,
    query,
    data?.callCenterPhone,
  );

  if (!number) return null;

  const onClickCaptureEvent =
    !shouldShowNumber || isMobile
      ? handleEvent('show_phone_click', {})
      : undefined;

  const formattedNumber = phoneNumberFormatter(number, {
    separators: 'p XXX XXX-XX-XX',
    prefix: '8',
  });

  const concealedNumber = `${phoneNumberFormatter(
    number.slice(0, number.length - 7),
    {
      separators: 'p XXX',
      prefix: '8',
    },
  )} xxx-xx-xx`;

  if (raw) {
    if (!shouldShowNumber && !isMobile) {
      return (
        <Button
          design="plain"
          onClick={() => {
            setShouldShowNumber(true);
          }}
          onClickCapture={onClickCaptureEvent}
        >
          {concealedNumber}
          <span
            className={cx('phone-number__link', 'phone-number__link_plain')}
          >
            Показать
          </span>
        </Button>
      );
    }

    return (
      <Link
        external
        href={`tel:${number}`}
        className={cx('phone-number', `phone-number_color_${color}`)}
        onClickCapture={onClickCaptureEvent}
      >
        {formattedNumber}
      </Link>
    );
  }

  if (!shouldShowNumber && !isMobile) {
    return (
      <Button
        design="plain"
        extraClassName={cx(
          'phone-number_hidden',
          `phone-number_color_${color}`,
        )}
        onClick={() => setShouldShowNumber(true)}
        onClickCapture={onClickCaptureEvent}
      >
        <div
          className={cx(
            'phone-number__caption',
            `phone-number__caption_${color}`,
          )}
        >
          Каждый день с 4 до 23
        </div>
        <div className="phone-number__placeholder">{concealedNumber}</div>
        <div
          className={cx('phone-number__link', {
            'phone-number__link_cyan': color === 'ruki-white',
          })}
        >
          Показать телефон
        </div>
      </Button>
    );
  }

  return (
    <>
      <div
        className={cx(
          'phone-number__caption',
          `phone-number__caption_${color}`,
        )}
      >
        Каждый день с 4 до 23
      </div>
      <Link
        external
        href={`tel:${number}`}
        className={cx('phone-number', `phone-number_color_${color}`)}
        onClickCapture={onClickCaptureEvent}
      >
        <span className="phone-number__number" id="call-center-phone">
          {formattedNumber}
        </span>
      </Link>
    </>
  );
};

export default PhoneNumber;
