import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const GuaranteeSmall: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  color,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox="0 0 26 26"
      className={extraClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9188 2.11787L13 0L11.0812 2.11787L8.55375 0.783996L7.47501 3.43042L4.64377 3.04142L4.53522 5.8972L1.74168 6.5L2.61641 9.22068L0.19751 10.7426L1.95001 13L0.19751 15.2574L2.61641 16.7793L1.74168 19.5L4.53522 20.1028L4.64377 22.9586L7.47501 22.5696L8.55375 25.216L11.0812 23.8821L13 26L14.9188 23.8821L17.4463 25.216L18.525 22.5696L21.3563 22.9586L21.4648 20.1028L24.2583 19.5L23.3836 16.7793L25.8025 15.2574L24.05 13L25.8025 10.7426L23.3836 9.22068L24.2583 6.5L21.4648 5.8972L21.3563 3.04142L18.525 3.43042L17.4463 0.783996L14.9188 2.11787ZM8.39369 10.9675L11.5232 14.8074L18.18 7.92928L18.9955 9.16279L11.9217 17.8165L10.6929 17.4017L7.50062 12.1261L8.39369 10.9675Z"
        fill={color}
      />
    </svg>
  );
};

export default GuaranteeSmall;
