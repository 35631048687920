import handsRuGetCatalogCategoryPage from 'queries/handsRuGetCatalogCategoryPage_5.graphql';
import {
  GeoUnitSlugType,
  ObjectPageSlugType,
  ServiceSlugType,
} from 'client/types/slug';
import {PriceType} from 'client/types/price';
import {FrequentlyAskedQuestion} from 'client/types/frequently-asked-question';
import {CatalogPageTypeEnum} from 'client/types/page-type';

export interface HandsRuGetCatalogCategoryPageData {
  catalog: {
    priceList?: {
      id: string;
      slug: string;
    };

    isPageGone: boolean;

    categoryPage: {
      tags: string[];
      name: string;
      nameTo: string;
      updatedAt: string;
      h1: string;
      reviewsH2: string;
      frequentlyAskedQuestions: FrequentlyAskedQuestion[];
      objectPages: {
        name: string;
        slug: ObjectPageSlugType;
        isIndexed: boolean;
        object: {name: string};
        mainServicePages: {
          name: string;
          slug: ServiceSlugType;
          service: {price: PriceType; minimumPrice: PriceType};
        }[];
        satelliteServicePages: {
          name: string;
          slug: ServiceSlugType;
          service: {price: PriceType; minimumPrice: PriceType};
        }[];
      }[];
      category: {
        name: string;
      };
    };
  };
}

export interface HandsRuGetCatalogCategoryPageVariables {
  slug: ObjectPageSlugType;
  geo: GeoUnitSlugType;
  pageType: CatalogPageTypeEnum;
}

export default handsRuGetCatalogCategoryPage;
