import React, {FunctionComponent} from 'react';

import cx from 'classnames';
import PriceUnit from 'client/ui/PriceUnit';
import {PriceUnitType} from 'client/types/price';
import formatNumber from '@pollex/utils/format-number';
import './Price.css';

interface OwnProps {
  extraClassName?: string;
  component?: any;
  amount: number;
  unit: PriceUnitType;
  'data-shmid'?: string;
}

const Price: FunctionComponent<OwnProps> = ({
  amount,
  unit,
  extraClassName,
  component: Component = 'span',
  ...restOfProps
}: OwnProps) => (
  <Component className={cx('price', extraClassName)} {...restOfProps}>
    <span className="price__amount" data-shmid="price-amount">
      {amount && formatNumber(amount)}
    </span>

    <span className="price__unit">
      <PriceUnit type={unit} />
    </span>
  </Component>
);

export default Price;
