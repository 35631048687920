import React, {FunctionComponent} from 'react';

import cx from 'classnames';
import PhoneNumber from 'client/components/PhoneNumber';

interface OwnProps {
  textColor: 'ruki-white' | 'ruki-black';
}

const PhoneBlock: FunctionComponent<OwnProps> = ({textColor}: OwnProps) => (
  <div className={cx('footer__block', 'footer__block_phone')}>
    <PhoneNumber color={textColor} />
  </div>
);

export default PhoneBlock;
