import React, {FunctionComponent} from 'react';

import cx from 'classnames';
import {PriceUnitType} from 'client/types/price';
import {generateNote} from 'client/utils/price';
import './PriceUnit.css';

interface OwnProps {
  type: PriceUnitType;
  component?: any;
  extraClassName?: string;
}

const PriceUnit: FunctionComponent<OwnProps> = ({
  type,
  extraClassName,
  component: Component = 'span',
  ...restOfProps
}: OwnProps) => {
  const title = generateNote({unit: type});

  switch (type) {
    case 'HOUR':
    case 'FIRST_HOUR': {
      return (
        <Component
          title={title}
          className={cx('ui-price-unit', extraClassName)}
          {...restOfProps}
        >
          &nbsp;&#8381;/ч
        </Component>
      );
    }

    case 'METER':
    case 'FOR_METER': {
      return (
        <Component
          title={title}
          className={cx('ui-price-unit', extraClassName)}
          {...restOfProps}
        >
          &nbsp;&#8381;/м
        </Component>
      );
    }

    case 'METER_SQUARED':
      return (
        <Component
          title={title}
          className={cx('ui-price-unit', extraClassName)}
          {...restOfProps}
        >
          &nbsp;&#8381;/м²
        </Component>
      );

    case 'PURCHASE_PERCENT':
      return (
        <Component
          title="процент от суммы покупки"
          className={cx('ui-price-unit', extraClassName)}
          {...restOfProps}
        >
          &nbsp;%
        </Component>
      );

    default:
      return <>&nbsp;&#8381;</>;
  }
};

export default PriceUnit;
