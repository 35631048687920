import {useEffect, useCallback} from 'react';

type Handler<E extends keyof WindowEventMap> = (
  this: Window,
  ev: WindowEventMap[E],
) => any;

const EMPTY_DEPENDENCIES = [];

export default function useEventListener<E extends keyof WindowEventMap>(
  name: E,
  handler: Handler<E>,
  dependencies: any[] = EMPTY_DEPENDENCIES,
): void {
  const listener = useCallback(handler, dependencies);

  useEffect(() => {
    const unlisten = addEventListener(name, listener);
    return unlisten;
  }, dependencies);
}

export function addEventListener<E extends keyof WindowEventMap>(
  name: E,
  handler: Handler<E>,
  options?: AddEventListenerOptions,
): () => void {
  window.addEventListener(name, handler, options);

  return (): void => {
    window.removeEventListener(name, handler, options);
  };
}
