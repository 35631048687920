import React, {FunctionComponent} from 'react';

import cx from 'classnames';
import './Icon.css';
import {IconProps, IconType} from './types';
import Telegram from './components/Telegram';
import Arrow from './components/Arrow';
import ArrowBold from './components/ArrowBold';
import ArrowHollow from './components/ArrowHollow';
import ArrowTailed from './components/ArrowTailed';
import ArrowTailed2 from './components/ArrowTailed2';
import ArrowTailed3 from './components/ArrowTailed3';
import Pencil from './components/Pencil';
import Catalog from './components/Catalog';
import Checkmark from './components/Checkmark';
import Phone from './components/Phone';
import Plus from './components/Plus';
import PlusHollow from './components/PlusHollow';
import MinusHollow from './components/MinusHollow';
import Star from './components/Star';
import StarEcho from './components/StarEcho';
import Close from './components/Close';
import Cross from './components/Cross';
import MagnifyingGlass from './components/MagnifyingGlass';
import Cart from './components/Cart';
import GuaranteeSmall from './components/GuaranteeSmall';
import ShieldCheckmark from './components/ShieldCheckmark';
import ShieldLock from './components/ShieldLock';
import ThumbsUp from './components/ThumbsUp';
import Document from './components/Document';
import Rouble from './components/Rouble';
import Geo from './components/Geo';
import Home from './components/Home';
import Trash from './components/Trash';
import TheatreMasks from './components/TheatreMasks';
import Hand from './components/Hand';
import PointingHand from './components/PointingHand';
import YandexMap from './components/YandexMap';
import GoogleMap from './components/GoogleMap';
import GraduationHat from './components/GraduationHat';
import Eye from './components/Eye';
import EyeCrossed from './components/EyeCrossed';

interface OwnProps extends IconProps {
  type: IconType;
}

const Icon: FunctionComponent<OwnProps> = props => {
  const {type, extraClassName} = props;
  const className = cx(
    'ui-icon-svg-themed',
    `ui-icon-svg-themed_type_${type}`,
    extraClassName,
  );

  if (
    [
      'arrow-bold-backward',
      'arrow-bold-forward',
      'arrow-bold-upward',
      'arrow-bold-downward',
    ].includes(type)
  ) {
    return <ArrowBold {...props} extraClassName={className} />;
  }

  if (
    [
      'arrow-backward',
      'arrow-forward',
      'arrow-upward',
      'arrow-downward',
    ].includes(type)
  ) {
    return <Arrow {...props} extraClassName={className} />;
  }

  if (type === 'arrow-downward-hollow') {
    return <ArrowHollow {...props} extraClassName={className} />;
  }

  if (type === 'arrow-tailed-forward') {
    return <ArrowTailed {...props} extraClassName={className} />;
  }

  if (type === 'arrow-tailed-forward-2') {
    return <ArrowTailed2 {...props} extraClassName={className} />;
  }

  if (type === 'arrow-tailed-forward-3') {
    return <ArrowTailed3 {...props} extraClassName={className} />;
  }

  if (type === 'pencil') {
    return <Pencil {...props} extraClassName={className} />;
  }

  if (type === 'catalog') {
    return <Catalog {...props} extraClassName={className} />;
  }

  if (type === 'checkmark') {
    return <Checkmark {...props} extraClassName={className} />;
  }

  if (type === 'phone') {
    return <Phone {...props} extraClassName={className} />;
  }

  if (type === 'plus') {
    return <Plus {...props} extraClassName={className} />;
  }

  if (type === 'plus-hollow') {
    return <PlusHollow {...props} extraClassName={className} />;
  }

  if (type === 'minus-hollow') {
    return <MinusHollow {...props} extraClassName={className} />;
  }

  if (type === 'star') {
    return <Star {...props} extraClassName={className} />;
  }

  if (type === 'star-echo') {
    return <StarEcho {...props} extraClassName={className} />;
  }

  if (type === 'close') {
    return <Close {...props} extraClassName={className} />;
  }

  if (type === 'x') {
    return <Cross {...props} extraClassName={className} />;
  }

  if (type === 'magnifying-glass') {
    return <MagnifyingGlass {...props} extraClassName={className} />;
  }

  if (type === 'cart') {
    return <Cart {...props} extraClassName={className} />;
  }

  if (type === 'guarantee-small') {
    return <GuaranteeSmall {...props} extraClassName={className} />;
  }

  if (type === 'shield-checkmark') {
    return <ShieldCheckmark {...props} extraClassName={className} />;
  }

  if (type === 'shield-lock') {
    return <ShieldLock {...props} extraClassName={className} />;
  }

  if (type === 'thumbs-up') {
    return <ThumbsUp {...props} extraClassName={className} />;
  }

  if (type === 'document') {
    return <Document {...props} extraClassName={className} />;
  }

  if (type === 'rouble') {
    return <Rouble {...props} extraClassName={className} />;
  }

  if (type === 'geo') {
    return <Geo {...props} extraClassName={className} />;
  }

  if (type === 'home') {
    return <Home {...props} extraClassName={className} />;
  }

  if (type === 'trash') {
    return <Trash {...props} extraClassName={className} />;
  }

  if (type === 'theatre-masks') {
    return <TheatreMasks {...props} extraClassName={className} />;
  }

  if (type === 'hand') {
    return <Hand {...props} extraClassName={className} />;
  }

  if (type === 'pointing-hand') {
    return <PointingHand {...props} extraClassName={className} />;
  }

  if (type === 'yandex-map') {
    return <YandexMap {...props} extraClassName={className} />;
  }

  if (type === 'google-map') {
    return <GoogleMap {...props} extraClassName={className} />;
  }

  if (type === 'graduation-hat') {
    return <GraduationHat {...props} extraClassName={className} />;
  }

  if (type === 'eye') {
    return <Eye {...props} extraClassName={className} />;
  }

  if (type === 'eye-crossed') {
    return <EyeCrossed {...props} extraClassName={className} />;
  }

  if (type === 'telegram') {
    return <Telegram {...props} extraClassName={className} />;
  }

  return null as never;
};

export default Icon;
