import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const ThumbsUp: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  color,
  viewBox,
}) => {
  return (
    <svg
      style={style}
      width={width || '610px'}
      height={height || '528px'}
      fill="none"
      viewBox={viewBox || '0 0 610 528'}
      xmlns="http://www.w3.org/2000/svg"
      className={extraClassName}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.956 236.261L0 176.783l208.602-.159-20.195-148.952L236.977 0 329.5 240.5l228.454-.452V240h44.962c4.497 28.614 7.084 62.147 7.084 98 0 35.436-2.527 68.606-6.928 97h-21.593l-.001.246H330.283L75.162 456l-11.76-41.014 37.323-11.365-44.48-15.319-14.315-49.414 41.412-14.824-50.104-16.307-12.781-43.485 48.57-20.259-57.07-7.752z"
        fill={color}
      />
    </svg>
  );
};

export default ThumbsUp;
