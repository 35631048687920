import React, {FunctionComponent, ReactNode} from 'react';

import cx from 'classnames';
import {HorizontalSideType, VerticalSideType} from 'client/types/side';
import './Tile.scss';

export interface TileProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  icon?: ReactNode;
  iconPosition?: {horizontal?: HorizontalSideType; vertical?: VerticalSideType};
  component?: any;
  color?: string;
  href?: string;
  external?: boolean;
  backgroundColor?: string;
  extraClassName?: string;
}

const Tile: FunctionComponent<TileProps> = ({
  component: ComponentProp = 'div',
  iconPosition = {horizontal: 'right'},
  extraClassName,
  children,
  icon,
  color,
  style,
  backgroundColor,
  ...restOfProps
}) => (
  <ComponentProp
    style={{color, backgroundColor, ...style}}
    className={cx('ui-tile', extraClassName)}
    {...restOfProps}
  >
    {children}
    {icon && (
      <div
        className={cx(
          'ui-tile__icon',
          `ui-tile__icon_position_${iconPosition.horizontal}`,
          {
            [`ui-tile__icon_position_${iconPosition.vertical}`]: iconPosition.vertical,
          },
        )}
      >
        {icon}
      </div>
    )}
  </ComponentProp>
);

export default Tile;
