import React, {
  useEffect,
  MouseEventHandler,
  FocusEventHandler,
  ChangeEventHandler,
  FunctionComponent,
  RefObject,
} from 'react';
import cx from 'classnames';
import MagnifyingGlass from '@pollex/components/icon/components/MagnifyingGlass';
import Arrow from '@pollex/components/icon/components/Arrow';
import Button from '@pollex/components/button';
import CircularProgress from '@pollex/components/circular-progress';
import './SearchInput.scss';
import useWindowOffset from 'client/hooks/useWindowOffset';

export interface SearchInputProps {
  isLoading: boolean;
  menuIsOpen: boolean;
  isFocused: boolean;
  extraClassName?: string;
  placeholder?: string;
  value: string;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  actionComponent: JSX.Element;
  onChange?: ChangeEventHandler;
  onClose?: MouseEventHandler;
  onMount?: () => void;
  menuHasItems?: boolean;
  innerRef?: RefObject<HTMLInputElement>;
  icon?: JSX.Element;
}

function scrollToTop() {
  if (window.innerWidth < 900) window.scroll(0, 0);
}

const SearchInput: FunctionComponent<SearchInputProps> = ({
  actionComponent: ActionComponent,
  onMount,
  onClose,
  isLoading,
  menuIsOpen,
  isFocused,
  extraClassName,
  value,
  menuHasItems,
  innerRef,
  icon,
  ...restOfProps
}) => {
  const offset = useWindowOffset();

  useEffect(() => {
    if (onMount) onMount();
  }, []);

  if (isFocused && !menuHasItems && offset > 0) scrollToTop();

  return (
    <div
      className={cx('search-input', extraClassName, {
        'search-input_focused': isFocused,
      })}
    >
      {menuIsOpen && (
        <Button
          className="search-input__close"
          design="plain"
          data-shmid="search-close"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            if (onClose) onClose(e);
          }}
        >
          <Arrow
            direction="left"
            color="var(--ruki-white)"
            width="12px"
            height="22px"
          />
        </Button>
      )}

      <div className="search-input__wrapper">
        <div className={cx('search-input__adornment', 'search-input__icon')}>
          <div
            className={cx(
              'search-input__adornment-core',
              'search-input__magnifying-glass',
            )}
          >
            {icon || (
              <MagnifyingGlass
                color="var(--link-color)"
                width="25"
                height="25"
                viewBox="0 0 25 25"
              />
            )}
          </div>
        </div>

        {value && (
          <>
            {isLoading ? (
              <div className="search-input__loader">
                <CircularProgress size="14px" />
              </div>
            ) : (
              ActionComponent
            )}
          </>
        )}

        <input
          data-shmid="input-core"
          className={cx('search-input__core', {
            'search-input__core-open': menuIsOpen,
          })}
          autoComplete="false"
          autoCorrect="false"
          spellCheck={false}
          value={value}
          ref={innerRef}
          inputMode="search"
          {...restOfProps}
        />
      </div>
    </div>
  );
};

export default SearchInput;
