import React, {FunctionComponent} from 'react';

import './Heading.scss';

const Heading: FunctionComponent = () => (
  <div className="navbar-heading">
    <div className="navbar-heading__img" />
  </div>
);

export default Heading;
