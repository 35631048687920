import React, {
  FunctionComponent,
  ReactNode,
  HTMLAttributeReferrerPolicy,
} from 'react';

import {Link as RouterLink, LinkProps} from 'react-router-dom';

interface OwnProps extends Omit<LinkProps, 'to' | 'style'> {
  href: string;
  title?: string;
  external?: boolean;
  className?: string;
  children: ReactNode;
  referrerPolicy?: HTMLAttributeReferrerPolicy;
}

const Link: FunctionComponent<OwnProps> = ({
  href,
  title,
  external,
  children,
  ...restOfProps
}: OwnProps) => {
  if (external) {
    return (
      <a href={href} title={title} {...restOfProps}>
        {children}
      </a>
    );
  }
  return (
    <RouterLink title={title} to={href} href={href} {...restOfProps}>
      {children}
    </RouterLink>
  );
};
export default Link;
