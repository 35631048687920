import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const Phone: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  color,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={extraClassName}
      width={width || '24'}
      height={height || '24'}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12c-2-2-2-4 0-6l4-4 4 4-3 3 6 6 3-3 4 4-4 4c-2 2-4 2-6 0l-8-8z"
        fill={color}
      />
    </svg>
  );
};

export default Phone;
