import React, {FunctionComponent} from 'react';
import cx from 'classnames';
import {IconProps} from '../../types';

import '../../Icon.css';

interface OwnProps {
  direction?: 'right' | 'down' | 'left' | 'up';
}

const Arrow: FunctionComponent<IconProps & OwnProps> = ({
  width,
  height,
  extraClassName,
  style,
  color,
  direction = 'right',
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox="0 0 42 74"
      className={cx(
        extraClassName,
        'ui-icon-svg-themed_type_arrow',
        `ui-icon-svg-themed_direction-${direction}`,
      )}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.2 73.8L0 67L34.6 36.8L0 5L7 0L41 33.5L42 39L5.2 73.8Z"
        fill={color}
      />
    </svg>
  );
};

export default Arrow;
