import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const ArrowTailed: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  color,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      className={extraClassName}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M3 2l2 2H0v2h5L3 8l2 2 5-5-5-5-2 2z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowTailed;
