import React, {
  FunctionComponent,
  ForwardRefRenderFunction,
  useEffect,
} from 'react';

import cx from 'classnames';
import ReactDOM from 'react-dom';
import keycode from 'keycode';
import usePortal from '@pollex/hooks/use-portal';
import useEventListener from '@pollex/hooks/use-event-listener';
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock-upgrade';
import Button from '../button';
import Icon from '../icon';
import './Modal.scss';

type ModalSize = 'large' | 'fullscreen' | 'photo' | void;

interface OwnProps {
  // couldnt make it work other way
  children: JSX.Element | JSX.Element[] | (JSX.Element | false)[];
  extraClassName?: string;
  size?: ModalSize;
  onClose: (e?: React.MouseEvent<any>) => void;
}

const Modal: ForwardRefRenderFunction<HTMLDivElement, OwnProps> = (
  {children, extraClassName, onClose, size},
  ref,
) => {
  const portal = usePortal({
    id: 'modal-root',
    onMount(_, modal) {
      if (modal) {
        modal.setAttribute('class', cx('modal-positioner', extraClassName));
      }

      const appRoot = document.body;
      if (appRoot) appRoot.setAttribute('modal-open', 'true');
    },
    onUnmount() {
      const appRoot = document.body;
      if (appRoot) appRoot.removeAttribute('modal-open');
    },
  });

  function handleKeyDown(e: KeyboardEvent): void {
    if (keycode(e) === 'esc') onClose();
  }

  useEventListener('keydown', handleKeyDown);

  const isPhotoModal = size === 'photo';

  useEffect(() => {
    if (portal && !isPhotoModal) {
      disableBodyScroll(portal.querySelector('.modal__inner') as HTMLElement);
    }
    return clearAllBodyScrollLocks;
  }, [portal, isPhotoModal]);

  if (!portal) return null;

  return ReactDOM.createPortal(
    <div className="modal" role="dialog" aria-modal="true" data-shmid="modal">
      {/* eslint-disable-next-line */}
      <div className="modal__backdrop" onClick={onClose} />
      <div
        className={cx('modal__inner', {
          modal__inner_large: size === 'large',
          modal__inner_fullscreen: size === 'fullscreen',
          modal__inner_photo: isPhotoModal,
        })}
        ref={ref}
      >
        <Button
          design="plain"
          onClick={onClose}
          data-shmid="modal-close"
          extraClassName={cx('modal__close', {
            modal__close_photo: isPhotoModal,
          })}
        >
          Закрыть
          <Icon
            type="x"
            width="22"
            height="22"
            color={isPhotoModal ? 'white' : 'var(--theme-accent-background)'}
          />
        </Button>

        {size === 'photo' ? (
          children
        ) : (
          <div className="modal__body">{children}</div>
        )}
      </div>
    </div>,
    portal,
  );
};

export const ModalTitle: FunctionComponent = ({children}) => (
  <h2 className="modal__title">{children}</h2>
);

export default React.forwardRef(Modal);
