import {useState, useEffect} from 'react';

export type MountHandler = () => void;

export default function useDidMount(
  onMount?: MountHandler,
  onUnmount?: MountHandler,
): boolean {
  const [isMounted, seIsMounted] = useState(false);

  useEffect(() => {
    seIsMounted(true);

    if (typeof onMount === 'function') onMount();

    return () => {
      if (typeof onUnmount === 'function') onUnmount();
    };
  }, []);

  return isMounted;
}
