import React, {ReactNode, FunctionComponent} from 'react';

import useDidMount, {MountHandler} from 'client/hooks/useDidMount';

interface OwnProps {
  children?: ReactNode;
  onMount?: MountHandler;
  onUnmount?: MountHandler;
  alt?: ReactNode;
}

const ClientSide: FunctionComponent<OwnProps> = ({
  children,
  onMount,
  onUnmount,
  alt,
}: OwnProps) => {
  const isMounted = useDidMount(onMount, onUnmount);

  if (!isMounted && alt) return <>{alt}</>;
  if (!isMounted) return null;
  if (!children) return null;

  return <>{children}</>;
};

export default ClientSide;
