import handsRuGetCategoryCoverImages from 'queries/handsRuGetCategoryCoverImages.graphql';
import {Image} from 'client/types/image';
import {CategorySlugType} from 'client/types/slug';
import {CategoryImagePosition} from 'client/components/CatalogPageCoverImage/constants';

export interface HandsRuGetCategoryCoverImageData {
  category: Record<CategoryImagePosition, Image>;
}

export interface HandsRuGetCategoryCoverImageVariables {
  slug: CategorySlugType;
}

export default handsRuGetCategoryCoverImages;
