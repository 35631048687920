import React, {FunctionComponent} from 'react';

import useMediaQuery from 'client/hooks/useMediaQuery';
import Sheet from '../Sheet';
import Hand from '../Hand';
import './SheetWithHands.scss';

interface OwnProps {
  standalone?: boolean;
}

const SheetWithHands: FunctionComponent<OwnProps> = ({
  children,
  standalone,
  ...restOfProps
}) => {
  const isDesktop = useMediaQuery('(min-width: 901px)');
  return (
    <div className="sheet-with-hands" {...restOfProps}>
      <Sheet standalone={standalone}>
        {children}
        {isDesktop && (
          <>
            <Hand side="left" />
            <Hand side="right" />
          </>
        )}
      </Sheet>
    </div>
  );
};

export default SheetWithHands;
