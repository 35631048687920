import {SearchResultType} from 'client/types/search';
import handsRuSearch from 'queries/handsRuSearch.graphql';

export interface HandsRuSearchVariables {
  inputValue: string;
}

export interface HandsRuSearchData {
  search: SearchResultType[];
}

export default handsRuSearch;
