import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const MagnifyingGlass: FunctionComponent<IconProps> = ({
  width = '32',
  height = '32',
  viewBox = '0 0 32 32',
  extraClassName,
  color = '#fff',
}) => {
  return (
    <svg
      className={extraClassName}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <circle cx="13" cy="13" r="8" stroke={color} strokeWidth="2" />
      <path stroke={color} strokeWidth="2" d="m18.7 18.3 9 9" />
    </svg>
  );
};

export default MagnifyingGlass;
