import React, {FunctionComponent} from 'react';

import Intercom from 'client/contexts/Analytics/trackers/Intercom';
import Button from '@pollex/components/button';
import Link from '@pollex/components/link';
import {useAnalytics} from 'client/contexts/Analytics';
import cx from 'classnames';
import {toTgDeeplink} from 'client/utils/urls';
import {useLocation} from 'client/contexts/Location';

interface OwnProps {
  color: 'ruki-white' | 'ruki-black';
}

const MessengersBlock: FunctionComponent<OwnProps> = () => {
  const {handleEvent} = useAnalytics();
  const location = useLocation();

  return (
    <div className="footer__block footer__block_messengers">
      <span className="footer__block-notation">Поможем в чате</span>
      <div className="footer__block__messengers-buttons">
        <Button
          component={Link}
          onClickCapture={handleEvent('footer_telegram_click', {})}
          onClick={() =>
            window.open(
              toTgDeeplink().serialize(location, {preserveQuery: true}),
            )
          }
          design="outlined"
          extraClassName={cx('footer__block__messengers-button')}
          // @ts-ignore
          external
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
            className="footer__block__messengers-button-icon"
          >
            <path
              fill="url(#paint0_linear_5191_11505)"
              d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16Z"
            />
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M7.234 15.827c4.663-2.025 7.767-3.37 9.326-4.023 4.436-1.852 5.368-2.172 5.968-2.185.133 0 .426.027.626.187.16.133.2.306.227.44.026.133.053.412.026.625-.24 2.532-1.279 8.673-1.812 11.498-.226 1.198-.666 1.598-1.092 1.638-.933.08-1.639-.613-2.531-1.199-1.412-.92-2.199-1.492-3.57-2.398-1.586-1.039-.56-1.612.346-2.544.24-.24 4.33-3.97 4.41-4.303.013-.04.013-.2-.08-.28-.094-.08-.227-.053-.334-.027-.146.027-2.384 1.519-6.74 4.463-.64.44-1.213.653-1.732.64-.573-.014-1.666-.32-2.492-.587-.999-.32-1.798-.493-1.732-1.052.04-.293.44-.586 1.186-.893Z"
              clipRule="evenodd"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5191_11505"
                x1="15.987"
                x2="15.987"
                y1="0"
                y2="31.747"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#2AABEE" />
                <stop offset="1" stopColor="#229ED9" />
              </linearGradient>
            </defs>
          </svg>
          Теlegram
        </Button>

        <Button
          component={Link}
          onClickCapture={handleEvent('footer_intercom_click', {})}
          onClick={() => Intercom.run('show')}
          design="outlined"
          extraClassName={cx('footer__block__messengers-button')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
            className="footer__block__messengers-button-icon"
          >
            <path
              fill="url(#paint0_linear_5191_11511)"
              d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16Z"
            />
            <path
              fill="#fff"
              d="M21.867 16.797a.534.534 0 0 1-1.067 0V12a.533.533 0 0 1 1.067 0v4.797Zm-.186 3.338c-.083.07-2.059 1.728-5.681 1.728s-5.598-1.657-5.68-1.728a.534.534 0 0 1 .693-.81c.03.025 1.795 1.472 4.987 1.472 3.232 0 4.969-1.457 4.986-1.472a.533.533 0 1 1 .695.81ZM10.133 12a.533.533 0 0 1 1.067 0v4.797a.534.534 0 0 1-1.067 0V12Zm2.667-1.067a.533.533 0 0 1 1.067 0v7.125a.533.533 0 0 1-1.067 0v-7.125Zm2.667-.27a.534.534 0 0 1 1.066 0v7.734a.533.533 0 0 1-1.066 0v-7.734Zm2.666.27a.534.534 0 0 1 1.067 0v7.125a.533.533 0 0 1-1.067 0v-7.125ZM22 8H10a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2Z"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5191_11511"
                x1="16"
                x2="16"
                y1="0"
                y2="32"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#00E6E6" />
                <stop offset="1" stopColor="#00F" />
              </linearGradient>
            </defs>
          </svg>
          На Сайте
        </Button>
      </div>
    </div>
  );
};

export default MessengersBlock;
