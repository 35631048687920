import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const Geo: FunctionComponent<IconProps> = ({
  width = '16',
  height = '24',
  color = '#FFF',
  extraClassName,
}) => {
  return (
    <svg
      className={extraClassName}
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.572 24s8.178-9 8.178-16.2c0-4.308-3.526-7.8-7.875-7.8C3.525 0 0 3.492 0 7.8 0 15 7.572 24 7.572 24Zm.304-13.2c1.673 0 3.03-1.343 3.03-3 0-1.656-1.357-3-3.03-3-1.673 0-3.029 1.344-3.029 3 0 1.657 1.356 3 3.03 3Z"
        fill={color}
      />
    </svg>
  );
};

export default Geo;
