import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const ArrowHollow: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  color,
  secondaryColor,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={extraClassName}
    >
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M23.031 28.638l.354.353.353-.353 16.789-16.789 3.535 3.536-20.677 20.678L2.707 15.384l3.536-3.536L23.03 28.638z"
          stroke={color}
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill={secondaryColor} d="M0 0h46v46H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowHollow;
