import React, {FunctionComponent} from 'react';
import cx from 'classnames';

import './Hand.scss';

interface OwnProps {
  side: 'left' | 'right';
}

const Hand: FunctionComponent<OwnProps> = ({side = 'left'}: OwnProps) => (
  <div className={cx('sheet-layout-hand', `sheet-layout-hand_${side}`)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="68px"
      height="157px"
      viewBox="0 0 88 157"
    >
      <polygon
        className="sheet-layout-hand__polygon"
        points="87 0 80.934 75.097 53.0048828 136.139 26.8046875 136.139 0 136.139 0 106.109 8.011 38.645 52.5 9.009 52.5 52.053 55 52.053 66 0"
      />
    </svg>
  </div>
);

export default Hand;
