import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const Rouble: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  color,
  viewBox,
}) => {
  return (
    <svg
      style={style}
      className={extraClassName}
      width={width || '40'}
      height={height || '49'}
      viewBox={viewBox || '0 0 40 49'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.869 0H6.807v49h7.147V29.075h25.438V0H13.87zm18.377 23.089H13.869V5.986h18.377v17.103z"
        fill={color}
      />
      <path
        d="M32.245 23.089H0v5.986h32.245v-5.986zM32.245 34.975H0v5.986h32.245v-5.986z"
        fill={color}
      />
    </svg>
  );
};

export default Rouble;
