import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const YandexMap: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  viewBox,
}) => {
  return (
    <svg
      style={style}
      className={extraClassName}
      width={width || '22'}
      height={height || '26'}
      viewBox={viewBox || '0 0 22 26'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8 0C5.05608 0 0.400002 4.65608 0.400002 10.4C0.400002 13.2709 1.56324 15.8704 3.4446 17.7523C5.32648 19.6352 9.76 22.36 10.02 25.22C10.059 25.6488 10.3694 26 10.8 26C11.2306 26 11.541 25.6488 11.58 25.22C11.84 22.36 16.2735 19.6352 18.1554 17.7523C20.0368 15.8704 21.2 13.2709 21.2 10.4C21.2 4.65608 16.5439 0 10.8 0Z"
        fill="#FF4433"
      />
      <path
        d="M10.8003 14.0403C12.8106 14.0403 14.4402 12.4106 14.4402 10.4003C14.4402 8.38993 12.8106 6.76025 10.8003 6.76025C8.78995 6.76025 7.16026 8.38993 7.16026 10.4003C7.16026 12.4106 8.78995 14.0403 10.8003 14.0403Z"
        fill="white"
      />
    </svg>
  );
};

export default YandexMap;
