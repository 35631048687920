import React, {FunctionComponent, ReactNode} from 'react';

import cx from 'classnames';
import {useTransition, animated} from 'react-spring';
import LinearProgress from 'client/components/Progress/LinearProgress';
import {Image} from 'client/types/image';
import StaticLayoutWidget, {WidgetProps} from '../StaticLayoutWidget';
import StaticLayoutBlock from '../StaticLayoutBlock';
import './StaticLayoutCover.scss';

interface OwnProps extends WidgetProps {
  extraClassName?: string;
  loading?: boolean;
  image?: Image;
  children: ReactNode;
  imageProps?: {
    extraClassName?: string;
  };
  withTransition?: boolean;
}

const DEFAULT_IMAGE_PROPS = {};

const StaticLayoutCover: FunctionComponent<OwnProps> = ({
  extraClassName,
  children,
  loading,
  image,
  imageProps = DEFAULT_IMAGE_PROPS,
  withTransition = true,
  ...restOfProps
}: OwnProps) => {
  const transitions = useTransition(
    image as Image,
    withTransition
      ? {
          from: {
            opacity: 0,
            transform: 'translate3d(0, 40px, 0)',
          },
          enter: {
            opacity: 1,
            transform: 'translate3d(0, 0, 0)',
          },
          leave: {
            opacity: 0,
            transform: 'translate3d(0, 40px, 0)',
          },
          key: image?.src,
        }
      : {},
  );

  return (
    <StaticLayoutWidget
      extraClassName={cx('static-layout-cover', extraClassName)}
      background="theme-default-background"
      position="relative"
      overflow="hidden"
      {...restOfProps}
    >
      {loading && (
        <div className="static-layout-cover__loader">
          <LinearProgress />
        </div>
      )}

      <StaticLayoutBlock padding="none" align="end">
        <div className="static-layout-cover__content">
          <div className="static-layout-cover__block">
            <div className="static-layout-cover__column">
              <div className="static-layout-cover__title">{children}</div>
            </div>
          </div>
        </div>
      </StaticLayoutBlock>

      {typeof image === 'object' &&
        transitions((props, item) => (
          <animated.div
            className={cx(
              'static-layout-cover__image',
              imageProps.extraClassName,
            )}
            style={{
              ...props,
              backgroundImage: `url(${(item && item.src) || ''})`,
            }}
          />
        ))}
    </StaticLayoutWidget>
  );
};

export default StaticLayoutCover;
