import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const Home: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  color,
  viewBox,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox={viewBox || '0 0 11 11'}
      fill={color || 'none'}
      xmlns="http://www.w3.org/2000/svg"
      className={extraClassName}
    >
      <path
        d="M3.92857 11H0V3.78992L5.5 0L11 3.78992V11H7.07143V6.28571H3.92857V11Z"
        fill="black"
      />
    </svg>
  );
};

export default Home;
