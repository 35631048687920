import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const Eye: FunctionComponent<IconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <mask
        id="eye"
        width="20"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
        style={{maskType: 'alpha'}}
      >
        <path fill="#D9D9D9" d="M0 0h20v20H0z" />
      </mask>
      <g mask="url(#eye)">
        <path
          stroke="#764ED4"
          strokeWidth="2"
          d="M16.9 10c-.3.6-1 1.9-2.1 3a6.7 6.7 0 0 1-4.8 2c-2 0-3.6-1-4.8-2-1.1-1.1-1.8-2.4-2.1-3 .3-.6 1-1.9 2.1-3C6.4 6 7.9 5 10 5c2 0 3.6 1 4.8 2 1.1 1.1 1.8 2.4 2.1 3Z"
        />
        <circle cx="10" cy="10" r="2" fill="#764ED4" />
      </g>
    </svg>
  );
};

export default Eye;
