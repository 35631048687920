import React, {FunctionComponent, ReactNode, Ref} from 'react';

import cx from 'classnames';
import Content from 'client/components/Content';
import './StaticLayoutWidget.scss';

export type WidgetPadding =
  | 'horizontal'
  | 'all'
  | 'all-but-top'
  | 'all-but-bottom'
  | 'top'
  | 'vertical'
  | 'bottom'
  | 'none';

interface CommonSettings {
  padding?: WidgetPadding;
}

export interface WidgetProps extends CommonSettings {
  children: ReactNode;
  grid?: boolean;
  gap?: boolean;
  extraClassName?: string;
  position?: '' | 'relative' | 'absolute' | 'static';
  overflow?: '' | 'hidden' | 'visible' | 'auto';
  'data-shmid'?: string;
  contentProps?: {
    extraClassName?: string;
  };
  background?:
    | 'none'
    | 'ruki-white'
    | 'ruki-black'
    | 'ruki-blue'
    | 'ruki-cyan'
    | 'ruki-weak-pink'
    | 'ruki-weak-blue'
    | 'theme-default-background'
    | 'theme-accent-background'
    | 'ruki-weak-purple';
  switchToFlexOn?: 'mobile' | 'tablet' | 'none';
  mobile?: CommonSettings;
  tablet?: CommonSettings;
  innerRef?: Ref<HTMLElement>;
}

const StaticLayoutWidget: FunctionComponent<WidgetProps> = ({
  children,
  grid = true,
  gap = true,
  extraClassName,
  contentProps = {},
  background = 'none',
  padding = 'all',
  overflow = '',
  position = '',
  switchToFlexOn = 'mobile',
  tablet,
  mobile,
  'data-shmid': shmid,
  innerRef,
}: WidgetProps) => {
  const m: {
    [x: string]: any;
  } = mobile || {};
  const t: {
    [x: string]: any;
  } = tablet || {};

  return (
    <section
      data-shmid={shmid}
      className={cx('static-layout-widget', extraClassName, {
        [`static-layout-widget_overflow_${overflow}`]: overflow,
        [`static-layout-widget_position_${position}`]: position,
        [`static-layout-widget_background_${background}`]: background,
      })}
      ref={innerRef}
    >
      <Content
        extraClassName={cx(
          'static-layout-widget__content',
          `static-layout-widget__content_switch-to-flex-on-${switchToFlexOn}`,
          `static-layout-widget__content_padding_${padding}`,
          contentProps.extraClassName,
          {
            [`static-layout-widget__content_tablet_padding-${t.padding}`]: t.padding,
            [`static-layout-widget__content_mobile_padding-${m.padding}`]: m.padding,
            'static-layout-widget__content_grid': grid,
            'static-layout-widget__content_grid_gap': gap,
          },
        )}
      >
        {children}
      </Content>
    </section>
  );
};

export default StaticLayoutWidget;
