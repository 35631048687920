import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const Cart: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  color,
}) => {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width={width || '37px'}
      height={height || '32px'}
      viewBox="0 0 37 32"
      fill="none"
      className={extraClassName}
    >
      <path
        fill={color}
        d="M7.9 8.3L6.5.1 0 0v3.1h4l3 20.5h25.5l4.5-13L7.9 8.3zm3.3 17.3C9.5 25.6 8 27 8 28.8c0 1.8 1.5 3.2 3.2 3.2 1.8 0 3.2-1.5 3.2-3.2 0-1.7-1.4-3.2-3.2-3.2zm17 0c-1.8 0-3.2 1.5-3.2 3.2 0 1.7 1.5 3.2 3.2 3.2 1.8 0 3.2-1.5 3.2-3.2 0-1.7-1.4-3.2-3.2-3.2z"
      />
    </svg>
  );
};

export default Cart;
