import React, {FunctionComponent} from 'react';
import cx from 'classnames';
import Anchor from '@pollex/components/anchor';
import Link from '@pollex/components/link';
import Cart from '@pollex/components/icon/components/Cart';
import {useLocation} from 'client/contexts/Location';
import {toClientCart} from 'client/utils/urls';
import {useAnalytics} from 'client/contexts/Analytics';
import {useCart} from 'client/contexts/Cart';
import ClientSide from 'client/components/ClientSide';

import './ClientCartLink.scss';

const ClientCartLink: FunctionComponent = () => {
  const cart = useCart();
  const location = useLocation();
  const {handleEvent} = useAnalytics();

  const clientCartItemCount = cart.entries.length;
  const isClientCartOpen = location.screenName === 'client_cart';

  return (
    <ClientSide
      alt={
        <Cart
          extraClassName="client-cart-link"
          color="var(--ruki-white)"
          width="24"
          height="24"
        />
      }
    >
      <Anchor
        onClick={e => {
          if (isClientCartOpen) {
            e.preventDefault();
            location.history.goBack();
          }
        }}
        component={Link}
        href={toClientCart().serialize(location)}
        extraClassName={cx('client-cart-link', {
          'client-cart-link--active': isClientCartOpen,
        })}
        title="Корзина"
        onClickCapture={handleEvent('cart_navbar_link_click', {})}
      >
        <Cart color="var(--ruki-white)" width="24" height="24" />

        {!cart.loading && clientCartItemCount > 0 && (
          <span className="client-cart-link__count">{clientCartItemCount}</span>
        )}
      </Anchor>
    </ClientSide>
  );
};

export default ClientCartLink;
