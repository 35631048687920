import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const Pencil: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  viewBox,
  color,
}) => {
  return (
    <svg
      style={style}
      width={width || '16'}
      height={height || '16'}
      viewBox={viewBox || '2 1 15 14'}
      fill="none"
      className={extraClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M11.1 2.6 15.4 7l-7.1 7L4 9.8l7-7ZM12.5 1.2a1 1 0 0 1 1.4 0l2.9 2.9c.4.3.4 1 0 1.4l-.7.7-4.3-4.3.7-.7ZM3.3 10.4l4.3 4.3-.7.7H2.6V11l.7-.7Z"
      />
    </svg>
  );
};

export default Pencil;
