import React, {FunctionComponent} from 'react';
import NB from 'client/components/NB';
// circular dependency
import StaticLayoutBlock from 'client/components/Layouts/StaticLayout/components/StaticLayoutBlock';
import StaticLayoutText from 'client/components/Layouts/StaticLayout/components/StaticLayoutText';

import './LegalNotice.scss';

const LegalNotice: FunctionComponent = () => (
  <NB>
    <StaticLayoutBlock areas="one, two, three" extraClassName="legal-notice">
      <StaticLayoutText size="small" extraClassName="legal-notice__text">
        Вне зависимости от&nbsp;употребляемых терминов опубликованная
        в&nbsp;настоящем разделе сайта информация не&nbsp;является предложением
        ООО &laquo;Сервис Про.Ремонт&raquo; о&nbsp;заключении с&nbsp;мастерами
        трудовых договоров, а&nbsp;представляет собой справочную информацию
        о&nbsp;возможных условиях сотрудничества мастеров и&nbsp;ООО
        &laquo;Сервис Про.Ремонт&raquo; в&nbsp;рамках Агентского договора
        в&nbsp;форме присоединения. Мы&nbsp;не&nbsp;заключаем с&nbsp;мастерами
        трудовые договоры, в&nbsp;связи с&nbsp;чем к&nbsp;отношениям между
        мастерами и&nbsp;ООО &laquo;Сервис Про.Ремонт&raquo; не&nbsp;подлежат
        применению нормы Трудового кодекса Российской Федерации, а&nbsp;также
        иные положения трудового законодательства
      </StaticLayoutText>
    </StaticLayoutBlock>
  </NB>
);

export default LegalNotice;
