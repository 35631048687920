import React, {
  ReactNode,
  FunctionComponent,
  MouseEventHandler,
  CSSProperties,
} from 'react';
import cx from 'classnames';
import CircularProgress from '../circular-progress';

import './Button.css';

interface OwnProps {
  children: ReactNode;
  design: 'filled' | 'outlined' | 'plain' | 'pill' | 'uppercase';
  size?: 'medium' | 'large';
  extraClassName?: string;
  className?: string;
  component?: any;
  disabled?: boolean;
  loading?: boolean;
  role?: string;
  tabIndex?: number | string;
  type?: string;
  href?: string;
  title?: string;
  'data-shmid'?: string;
  onClick?: MouseEventHandler;
  onClickCapture?: MouseEventHandler;
  onMouseDown?: MouseEventHandler;
  style?: CSSProperties;
}

const Button: FunctionComponent<OwnProps> = (props: OwnProps) => {
  const {
    children,
    extraClassName,
    disabled,
    loading,
    size = '',
    title,
    design,
    component: Component = 'button',
    type = 'button',
    role = 'button',
    tabIndex,
    ...restOfProps
  } = props;

  const buttonProps = Component === 'button' ? {role, type} : {};

  return (
    <Component
      title={title}
      tabIndex={disabled ? '-1' : tabIndex}
      disabled={disabled || loading}
      className={cx(
        'button',
        `button_design_${design}`,
        {
          button_disabled: disabled,
          [`button_size_${size}`]: size,
          [`button_design_${design}_size_${size}`]: size,
          [`button_design_${design}_disabled`]: disabled,
        },
        extraClassName,
      )}
      {...buttonProps}
      {...restOfProps}
    >
      {loading ? (
        <>
          {/* Safari does not rerender button text after loading correctly */}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <CircularProgress
            color={
              design === 'outlined'
                ? 'var(--link-color)'
                : 'var(--theme-accent-text)'
            }
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : (
        children
      )}
    </Component>
  );
};

export default Button;
