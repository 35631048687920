import {useState, useEffect} from 'react';

export default function useWindowOffset(): number {
  const [offset, changeOffset] = useState(0);

  function updateOffset(): void {
    changeOffset(window.pageYOffset);
  }

  useEffect(() => {
    window.addEventListener('scroll', updateOffset);
    return () => window.removeEventListener('scroll', updateOffset);
  }, []);

  return offset;
}
