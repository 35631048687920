import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const Hand: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  viewBox,
  style,
  color,
}) => {
  return (
    <svg
      className={extraClassName}
      style={style}
      width={width || '350'}
      height={height || '411'}
      viewBox={viewBox || '0 0 350 411'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M264.972-79L104.931 199.985 0 231.421l4.337 14.64 64.297-1.079L1.015 359.218l10.695 10.354 57.625-81.519 3.22 2.758-40.468 97.534 13.612 8.047 47.494-95.464 4.75 1.603-32.967 101.543 13.9 6.926 40.128-99.257 4.282 1.321-19.013 84.953 14.21 2.427 44.215-164.071L350-79h-85.028z"
        fill={color}
      />
    </svg>
  );
};

export default Hand;
