import React, {CSSProperties, FunctionComponent} from 'react';

import cx from 'classnames';
import './LinearProgress.css';

interface OwnProps {
  extraClassName?: string;
  value?: number;
}

const LinearProgress: FunctionComponent<OwnProps> = ({
  extraClassName,
  value,
  ...restOfProps
}) => {
  const rootProps = {};
  const inlineStyles = {
    bar1: {transform: undefined} as CSSProperties,
    bar2: {},
  };

  if (typeof value !== 'undefined') {
    rootProps['aria-valuenow'] = Math.round(value);
    inlineStyles.bar1.transform = `scaleX(${value / 100})`;
  }

  const isDeterminate = typeof value !== 'undefined';

  return (
    <div
      className={cx('linear-progress', extraClassName)}
      role="progressbar"
      {...rootProps}
      {...restOfProps}
    >
      <div
        className={cx('linear-progress__bar', 'linear-progress__bar1', {
          'linear-progress__bar1_indeterminate': !isDeterminate,
        })}
        style={inlineStyles.bar1}
      />
      {isDeterminate ? null : (
        <div
          className={cx(
            'linear-progress__bar',
            'linear-progress__bar2',
            'linear-progress__bar2_indeterminate',
          )}
          style={inlineStyles.bar2}
        />
      )}
    </div>
  );
};

export default LinearProgress;
