import {HTML} from 'client/types/html';
import {CityID} from 'client/types/id';
import handsRuGetHolidayNotice from 'queries/handsRuGetHolidayNotice.graphql';

interface BusinessComponent {
  content?: {
    html: HTML;
  };
}

export interface HandsRuGetHolidayNoticeData {
  cities: {
    components: BusinessComponent[];
  }[];
}

export interface HandsRuGetHolidayNoticeVariables {
  cityId: CityID;
}

export default handsRuGetHolidayNotice;
