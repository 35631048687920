import React, {FunctionComponent} from 'react';

import cx from 'classnames';
import {HTML} from 'client/types/html';

interface OwnProps {
  children?: HTML;
  extraClassName?: string;
  component?: any;
}

const HTMLWrapper: FunctionComponent<OwnProps> = ({
  children,
  component: Component = 'span',
  extraClassName,
}: OwnProps) => (
  <Component
    className={cx('html-wrapper', extraClassName)}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{__html: children}}
  />
);

export default HTMLWrapper;
