export default function plural(
  number: number,
  one: string,
  two: string,
  five: string,
): string {
  let coefficient = Math.abs(number);

  coefficient %= 100;
  if (coefficient >= 5 && coefficient <= 20) {
    return five;
  }

  coefficient %= 10;
  if (coefficient === 1) {
    return one;
  }

  if (coefficient >= 2 && coefficient <= 4) {
    return two;
  }

  return five;
}
