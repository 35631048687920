import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const ShieldLock: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  color,
  viewBox,
  secondaryColor,
}) => {
  return (
    <svg
      style={style}
      width={width || '371px'}
      height={height || '421px'}
      viewBox={viewBox || '0 0 371 421'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={extraClassName}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185.66.833S60.864 27.042.32 38.274c-3.089 206.522 66.26 323.935 185.34 381.893C287.288 381.079 366.675 298.71 371 38.274 307.984 26.593 185.66.834 185.66.834z"
        fill={color}
      />
      <circle cx="187.523" cy="151.794" r="33.547" fill={secondaryColor} />
      <path
        d="M221.953 254.528l-1.554 4.083-61.997 5.006-3.445-5.073 32.878-129.113 34.118 125.097z"
        fill={secondaryColor}
      />
    </svg>
  );
};

export default ShieldLock;
