import React, {FunctionComponent} from 'react';
import cx from 'classnames';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import typographize from '@pollex/utils/typographize';
import keycode from 'keycode';
import Icon from '../icon';
import Anchor from '../anchor';

import './CityDirectory.scss';

interface SomeCityComponent {
  name: string;
  whatWeDoText?: string;
}

interface SomeCity {
  id: string;
  name: string;
  components: SomeCityComponent[];
}

interface OwnProps<T extends SomeCity> {
  currentId: string;
  items: T[];
  onSelect: (city: T) => void;
}

function CityDirectory<T extends SomeCity>({
  items,
  currentId,
  onSelect,
}: OwnProps<T>): ReturnType<FunctionComponent<OwnProps<T>>> {
  return (
    <table className="city-directory">
      <tbody>
        {sortBy(Object.entries(groupBy(items, item => item.name[0])), [
          group => group[0].charCodeAt(0),
        ]).map(([letter, cities]) =>
          cities.map((city, i) => {
            const {id, name, components} = city;
            const notice = components.find(
              c => c.name === 'UNDER_CONSTRUCTION_NOTICE',
            );

            return (
              <tr
                key={id}
                className={cx('city-directory__row', {
                  'city-directory__row_leading': i === 0,
                })}
              >
                <td
                  className={cx(
                    'city-directory__cell city-directory__cell_letter',
                    {
                      'city-directory__cell_empty': i > 0,
                    },
                  )}
                  id={i === 0 ? `list-letter-${letter}` : undefined}
                >
                  {i === 0 && letter}
                </td>
                <td className="city-directory__cell city-directory__cell_cities">
                  <Anchor
                    component="div"
                    design={currentId === city.id ? 'plain' : 'default'}
                    tabIndex={0}
                    themed={false}
                    role="link"
                    onKeyDown={e => {
                      if (keycode(e as any) === 'enter') {
                        e.preventDefault();
                        onSelect(city);
                      }
                    }}
                    onClick={() => {
                      onSelect(city);
                    }}
                  >
                    {name === 'Москва' && (
                      <Icon
                        type="star"
                        color="var(--ruki-blue)"
                        width="18px"
                        height="18px"
                        extraClassName="city-directory__item-star"
                      />
                    )}

                    {typographize(name)}

                    <p className="city-directory__item-note">
                      {notice ? notice.whatWeDoText : 'Все услуги'}
                    </p>
                  </Anchor>
                </td>
              </tr>
            );
          }),
        )}
      </tbody>
    </table>
  );
}

export default CityDirectory;
