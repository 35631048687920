import React, {FunctionComponent} from 'react';
import {useMeta} from 'client/contexts/Meta';

const NB: FunctionComponent = ({children}) => {
  const {botType} = useMeta();
  if (!botType || botType === 'N') return <>{children}</>;
  return null;
};

export default NB;
