import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const Cross: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  color,
  viewBox,
}) => {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      className={extraClassName}
    >
      <line x1="0" y1="0" x2="100%" y2="100%" stroke={color} strokeWidth={2} />
      <line x1="100%" y1="0" x2="0" y2="100%" stroke={color} strokeWidth={2} />
    </svg>
  );
};

export default Cross;
