import React, {FunctionComponent, memo} from 'react';
import {Route, Switch} from 'react-router-dom';
import Anchor from '@pollex/components/anchor';
import {useLocation} from 'client/contexts/Location';
import {useAnalytics} from 'client/contexts/Analytics';
import Link from '@pollex/components/link';
import Catalog from '@pollex/components/icon/components/Catalog';
import Search from 'client/components/Search';
import Content from 'client/components/Content';
import Heading from 'client/components/Heading';
import Logo from 'client/components/Logo';
import {toClassifier, toMain} from 'client/utils/urls';
import {LEGACY_PARTNER_FORMS} from 'client/utils/partner';
import CitySelect from 'client/components/CitySelect';
import ClientOrderLink from './components/ClientOrderLink';
import ClientCartLink from './components/ClientCartLink';

import './Navbar.scss';

const Navbar: FunctionComponent = () => {
  return (
    <Switch>
      <Route
        path={`/(partner|${LEGACY_PARTNER_FORMS.join('|')}|happynew2023)/`}
        component={Nav}
      />

      <Route
        exact
        path="/"
        component={() => <Nav withCatalogLink withControlLinks />}
      />

      <Route
        path="*"
        component={() => <Nav withSearchBar withCatalogLink withControlLinks />}
      />
    </Switch>
  );
};

interface NavProps {
  withCatalogLink?: boolean;
  withSearchBar?: boolean;
  withControlLinks?: boolean;
}

const Nav: FunctionComponent<NavProps> = ({
  withCatalogLink = false,
  withSearchBar = false,
  withControlLinks = false,
}) => {
  const {handleEvent} = useAnalytics();
  const location = useLocation();

  return (
    <nav className="navbar">
      <Content extraClassName="navbar__wrapper">
        <Anchor
          component={Link}
          href={toMain().serialize(location, {preserveQuery: false})}
          onClickCapture={handleEvent('logo_click', {})}
          className="navbar__logo"
          title="На главную"
        >
          <Logo />
          <Heading />
        </Anchor>

        {withCatalogLink && (
          <div className="navbar__catalog-section">
            <Link
              className="navbar__catalog"
              href={toClassifier().serialize(location, {
                preserveQuery: false,
              })}
              onClickCapture={handleEvent('catalog-link_click', {})}
              aria-label="Каталог услуг"
            >
              <Catalog
                extraClassName="navbar__catalog-icon"
                color="var(--theme-accent-text)"
                width="32"
              />
              <span>Все услуги</span>
            </Link>
          </div>
        )}

        {withSearchBar && (
          <div className="navbar__search-section">
            <Search type="NAVBAR" />
          </div>
        )}

        {withControlLinks && (
          <div className="navbar__controls-section">
            <CitySelect />
            <ClientOrderLink />
            <ClientCartLink />
          </div>
        )}
      </Content>
    </nav>
  );
};

export default memo(Navbar);
