import React, {FunctionComponent} from 'react';

import cx from 'classnames';
import {Route, Switch} from 'react-router-dom';
import Button from '@pollex/components/button';
import Anchor from '@pollex/components/anchor';
import Link from '@pollex/components/link';
import css from 'common-tags/lib/oneLine';
import {Helmet} from 'react-helmet';
import Content from 'client/components/Content';
import AppBadge from 'client/components/AppBadge';
import Logo from 'client/components/Logo';
import Heading from 'client/components/Heading';
import Nobr from 'client/components/Nobr';
import {LEGACY_PARTNER_FORMS} from 'client/utils/partner';
import {useLocation} from 'client/contexts/Location';
import {toClientOrders, toPrivacyPolicy, toTermsOfUse} from 'client/utils/urls';
import useMediaQuery from 'client/hooks/useMediaQuery';
import LegalNotice from './components/LegalNotice';
import Links from './components/Links';
import PhoneBlock from './components/PhoneBlock';
import MessengersBlock from './components/Messengers';

import './Footer.scss';
import './FooterMobile.scss';

interface OwnProps {
  themed?: boolean;
}

const Footer: FunctionComponent<OwnProps> = ({themed}: OwnProps) => {
  const color = themed ? 'ruki-black' : 'ruki-white';
  const isMobile = useMediaQuery('(max-width: 600px)');
  const location = useLocation();

  const currentYear = new Date().getFullYear();

  return (
    <>
      {themed && (
        <Helmet>
          <style>{css`
            body {
              background: var(--ruki-white);
            }
          `}</style>
        </Helmet>
      )}
      <Switch>
        <Route
          path={`/(partner|${LEGACY_PARTNER_FORMS.join('|')})/`}
          render={() => null}
        />

        <Route
          exact
          path="/"
          render={() => (
            <footer
              data-shmid="footer"
              className={cx('footer', {footer_themed: themed})}
            >
              <Content extraClassName="footer__content">
                <div className="footer__block footer__block_logo">
                  <p className="footer__logo" title="На главную">
                    <Logo />
                  </p>
                  <p className="footer__heading" title="На главную">
                    <Heading />
                  </p>
                </div>

                <div className={cx('footer__block', 'footer__block_links')}>
                  <Links themed={themed} isMain />
                </div>
                <PhoneBlock textColor={color} />

                <MessengersBlock color={color} />

                <div className="footer__block  footer__block_leave-review">
                  <span className="footer__block-notation">
                    Уже вызывали Руки?
                  </span>
                  <Button
                    component={Link}
                    href={toClientOrders().serialize(location)}
                    design="outlined"
                    extraClassName="footer__block__leave-review-button"
                  >
                    Напишите отзыв
                  </Button>
                </div>

                <div className={cx('footer__block', 'footer__block_app')}>
                  <div>
                    <span className="footer__block-notation">
                      Следите за заказом в приложении
                    </span>

                    <div className="footer__get-app-badges">
                      <AppBadge type="iOS" color={color} />
                      <AppBadge type="Android" color={color} />
                      <AppBadge type="AppGallery" color={color} />
                    </div>
                  </div>
                </div>

                <div
                  className={cx(
                    'footer__block',
                    'footer__block_legal-links',
                    'footer__block_legal-links-main',
                    `footer__block-legal-note_${color}`,
                  )}
                >
                  <Anchor
                    href={toTermsOfUse().serialize(location)}
                    component={Link}
                    extraClassName={cx(
                      'footer__legal-link',
                      `footer__legal-link_${color}`,
                    )}
                  >
                    Условия использования
                  </Anchor>
                  <Anchor
                    href={toPrivacyPolicy().serialize(location)}
                    component={Link}
                    extraClassName={cx(
                      'footer__legal-link',
                      `footer__legal-link_${color}`,
                    )}
                  >
                    Политика обработки данных
                  </Anchor>
                </div>

                <div className="footer__block footer__block-legal-note">
                  <Nobr>© 2018-{currentYear}</Nobr>{' '}
                  <Nobr>ООО Сервис ПРО.Ремонт</Nobr> осуществляет деятельность
                  в&nbsp;области информационных технологий.{' '}
                  <Nobr>Виды деятельности (коды): 2.01, 15.01.</Nobr>
                </div>
              </Content>
            </footer>
          )}
        />

        <Route
          path="*"
          render={() => (
            <footer
              data-shmid="footer"
              className={cx('footer', {
                footer_themed: themed,
              })}
            >
              <Content extraClassName="footer__content">
                <PhoneBlock textColor={color} />

                {isMobile && <MessengersBlock color={color} />}

                <div
                  className={cx(
                    'footer__block',
                    'footer__block_links',
                    'footer__block_end',
                  )}
                >
                  <Links themed={themed} />
                </div>

                <div
                  className={cx(
                    'footer__block',
                    'footer__block_app',
                    'footer__block_app_mobile',
                  )}
                >
                  <div>
                    <span
                      className={cx(
                        'footer__get-app',
                        `footer__block__color_${color}`,
                      )}
                    >
                      Скачайте наше приложение
                    </span>

                    <div className="footer__get-app-badges">
                      <AppBadge
                        type="iOS"
                        color={color}
                        extraClassName="footer__get-ios-app"
                      />
                      <AppBadge
                        type="Android"
                        color={color}
                        extraClassName="footer__get-android-app"
                      />
                      <AppBadge
                        type="AppGallery"
                        color={color}
                        extraClassName="footer__get-app-gallery-app"
                      />
                    </div>
                  </div>
                </div>

                <Route
                  path={[
                    '/specialist/',
                    '/specialist/register/',
                    '/specialist/:slug/',
                  ]}
                  render={() => <LegalNotice />}
                />

                <div
                  className={cx(
                    'footer__block footer__block-legal-note',
                    `footer__block-legal-note_${color}`,
                  )}
                >
                  <Nobr>© 2018-{currentYear}</Nobr>{' '}
                  <Nobr>ООО Сервис ПРО.Ремонт</Nobr>
                </div>

                <div className="footer__block footer__block_legal-links">
                  <Anchor
                    href={toTermsOfUse().serialize(location)}
                    component={Link}
                    extraClassName={cx(
                      'footer__legal-link',
                      `footer__legal-link_${color}`,
                    )}
                  >
                    Условия использования
                  </Anchor>
                  <Anchor
                    href={toPrivacyPolicy().serialize(location)}
                    component={Link}
                    extraClassName={cx(
                      'footer__legal-link',
                      `footer__legal-link_${color}`,
                    )}
                  >
                    Политика обработки данных
                  </Anchor>
                </div>
              </Content>
            </footer>
          )}
        />
      </Switch>
    </>
  );
};

export default Footer;
