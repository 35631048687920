import React, {FunctionComponent} from 'react';
import cx from 'classnames';
import BreadcrumbLink from 'client/ui/BreakcrumbLink';
import BreadcrumbListStructuredData from 'client/components/StructuredData/BreadcrumbList';

import './Breadcrumbs.scss';

export interface BreadcrumbType {
  text: string;
  pathname: string;
  clickable?: boolean;
}

interface OwnProps {
  links: BreadcrumbType[];
  extraClassName?: string;
  hasMicroData?: boolean;
}

const Breadcrumbs: FunctionComponent<OwnProps> = ({
  links = [],
  extraClassName = '',
  hasMicroData,
}: OwnProps) => {
  if (!(links && links.length)) return null;

  return (
    <>
      {hasMicroData && <BreadcrumbListStructuredData links={links} />}
      <ol className={cx('ui-breadcrumbs', extraClassName)}>
        {links.map(({text, pathname, clickable = true}, index) => (
          <BreadcrumbLink
            href={pathname}
            key={pathname}
            active={index === links.length - 1 || !clickable}
          >
            {text}
          </BreadcrumbLink>
        ))}
      </ol>
    </>
  );
};

export default Breadcrumbs;
