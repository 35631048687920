import React, {FunctionComponent} from 'react';
import cx from 'classnames';

import {IconProps} from '../../types';
import '../../Icon.css';

interface OwnProps {
  direction?: 'right' | 'down' | 'left' | 'up';
}

const ArrowBold: FunctionComponent<IconProps & OwnProps> = ({
  width = '5',
  height = '8',
  viewBox = '0 0 5 8',
  extraClassName,
  style,
  color,
  direction = 'right',
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx(
        extraClassName,
        'ui-icon-svg-themed_type_arrow',
        `ui-icon-svg-themed_direction-${direction}`,
      )}
      viewBox={viewBox}
    >
      <path
        d="M.195 6.862a.667.667 0 0 0 .943.943L4.471 4.47a.667.667 0 0 0 0-.942L1.138.195a.667.667 0 1 0-.943.943L3.057 4 .195 6.862Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowBold;
