/* eslint-disable react/no-danger */
import React, {FunctionComponent} from 'react';
import {WithContext, BreadcrumbList} from 'schema-dts';
import {BreadcrumbType} from 'client/ui/Breadcrumbs';

interface OwnProps {
  links: BreadcrumbType[];
}

const BreadcrumbListStructuredData: FunctionComponent<OwnProps> = ({links}) => {
  const itemListElement: BreadcrumbList['itemListElement'] = links.map(
    ({text, pathname}: BreadcrumbType, position) => {
      return {
        '@type': 'ListItem',
        position: position + 1,
        item: {
          '@id': position < links.length - 1 ? pathname : (undefined as any),
          name: `🟢 ${text}`,
        },
      };
    },
  );

  const data: WithContext<BreadcrumbList> = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(data),
      }}
    />
  );
};

export default BreadcrumbListStructuredData;
