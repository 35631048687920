import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const EyeCrossed: FunctionComponent<IconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <mask
        id="eye_crossed"
        width="20"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
        style={{maskType: 'alpha'}}
      >
        <path fill="#D9D9D9" d="M0 0h20v20H0z" />
      </mask>
      <g mask="url(#eye_crossed)">
        <path
          stroke="#764ED4"
          strokeWidth="2"
          d="M16.9 10c-.3.6-1 1.9-2.1 3a6.7 6.7 0 0 1-4.8 2c-2 0-3.6-1-4.8-2-1.1-1.1-1.8-2.4-2.1-3 .3-.6 1-1.9 2.1-3C6.4 6 7.9 5 10 5c2 0 3.6 1 4.8 2 1.1 1.1 1.8 2.4 2.1 3Z"
        />
        <circle cx="10" cy="10" r="2" fill="#764ED4" />
        <mask
          id="eye_crossed_b"
          width="17"
          height="17"
          x="2"
          y=".6"
          fill="#000"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M2 .6h17v17H2z" />
          <path d="M2 3.4 3.4 2l14.2 14.1-1.5 1.5L2 3.4Z" />
        </mask>
        <path fill="#764ED4" d="M2 3.4 3.4 2l14.2 14.1-1.5 1.5L2 3.4Z" />
        <path
          fill="#F9F9FB"
          d="m2 3.4 14.1 14.2 2.9-2.9L4.8.6 2 3.4Z"
          mask="url(#eye_crossed_b)"
        />
      </g>
    </svg>
  );
};

export default EyeCrossed;
