import React, {FunctionComponent} from 'react';
import cx from 'classnames';
import './Sheet.scss';

interface OwnProps {
  standalone?: boolean;
}

const Sheet: FunctionComponent<OwnProps> = ({children, standalone = false}) => (
  <div
    className={cx('sheet', {
      sheet_standalone: standalone,
      sheet_embedded: !standalone,
    })}
  >
    {children}
  </div>
);

export default Sheet;
