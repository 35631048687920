import React, {FunctionComponent} from 'react';

import cx from 'classnames';
import './Content.css';

interface OwnProps extends React.HTMLAttributes<HTMLDivElement> {
  extraClassName?: string;
}

const Content: FunctionComponent<OwnProps> = ({
  children,
  extraClassName,
  ...restOfProps
}) => (
  <div className={cx('content', extraClassName)} {...restOfProps}>
    {children}
  </div>
);

export default Content;
