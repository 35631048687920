import React, {FunctionComponent} from 'react';

import cx from 'classnames';
import Breadcrumbs, {BreadcrumbType} from 'client/ui/Breadcrumbs';
import './StaticLayoutBreadcrumbs.scss';

interface OwnProps {
  extraClassName?: string;
  loading?: boolean;
  links: BreadcrumbType[];
  hasMicroData?: boolean;
}

const StaticLayoutBreadcrumbs: FunctionComponent<OwnProps> = ({
  extraClassName,
  loading,
  links,
  hasMicroData,
  ...restOfProps
}: OwnProps) =>
  links?.length ? (
    <div
      className={cx('static-layout-breadcrumbs', extraClassName)}
      {...restOfProps}
    >
      <Breadcrumbs links={links} hasMicroData={hasMicroData} />
    </div>
  ) : null;

export default StaticLayoutBreadcrumbs;
