import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const Checkmark: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  color,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox="0 0 64 59"
      fill="none"
      className={extraClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 27.573l24.276 26.573L64 7.643 57.38 1 24.275 40.86 6.62 20.93 0 27.573z"
        fill={color}
      />
    </svg>
  );
};

export default Checkmark;
