import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const GraduationHat: FunctionComponent<IconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" fill="none">
      <path d="M7 5h8v5l-1.001 1h-6l-1-1L7 5Z" fill="#fff" />
      <path
        d="M11.1756 1.5318 11 1.466l-.1756.0658-8 3L1.576 5l1.2484.4682 8 3L11 8.534l.1756-.0658 8-3L20.424 5l-1.2484-.4682-8-3Z"
        fill="#fff"
      />
      <path d="m6.999 12-1.5 2-1.5-2 1.5-1.5 1.5 1.5Z" fill="#fff" />
      <path d="M5.5 5v7" stroke="#fff" />
    </svg>
  );
};

export default GraduationHat;
