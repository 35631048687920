import React, {FunctionComponent} from 'react';
import Content from 'client/components/Content';
import {useTheme, useTileColors} from 'client/contexts/Theme';
import {useLocation} from 'client/contexts/Location';
import {
  useBusinessComponents,
  UnderConstructionNoticeType,
  useCity,
} from 'client/contexts/City';
import {useAnalytics} from 'client/contexts/Analytics';
import Link from '@pollex/components/link';
import {toCategory} from 'client/utils/urls';
import Tile from 'client/ui/Tile';
import Anchor from '@pollex/components/anchor';
import HTMLWrapper from 'client/components/HTMLWrapper';
import handsRuGetHolidyNotice, {
  HandsRuGetHolidayNoticeData,
  HandsRuGetHolidayNoticeVariables,
} from 'query-wrappers/HandsRuGetHolidayNotice';

import './Notice.scss';
import {useQuery} from '@apollo/client';

const UnderConstructionNotice: FunctionComponent = () => {
  const {handleEvent} = useAnalytics();
  const theme = useTheme();
  const location = useLocation();
  const {current} = useCity();

  const [notice, leroyNotice] = useBusinessComponents<
    UnderConstructionNoticeType[]
  >(['UNDER_CONSTRUCTION_NOTICE', 'LEROY_KITCHEN_NOTICE']);

  const {
    categories,
    categories: [noticeCategory],
    callToAction,
    title,
    caption,
  } = notice || {categories: []};

  const category = theme.cache.get(noticeCategory?.slug) || theme;
  const [background, color] = useTileColors(category.slug);
  const {screenName} = location;

  if (!noticeCategory) return null;

  if (['cart', 'order_success', 'partner_order'].includes(screenName)) {
    return null;
  }

  if (leroyNotice && screenName === 'main') return null;

  if (categories.find(c => c.slug === theme.slug)) return null;

  if (!category) return null;
  if (!callToAction) return null;

  return (
    <Tile
      extraClassName="notice"
      component={Link}
      href={toCategory(category).serialize(location)}
      color={color}
      backgroundColor={background}
      onClickCapture={handleEvent('notice_click', {
        thirdLevelDomain: current && current.thirdLevelDomain,
      })}
    >
      <Content
        extraClassName="notice__content"
        style={
          category?.image ? {backgroundImage: `url(${category.image.src})`} : {}
        }
      >
        <div className="notice__text">
          {title}. <span className="notice__caption">{caption}</span>
        </div>

        <Anchor extraClassName="notice__cta" component="span">
          {callToAction}
          <span className="notice__arrow" />
        </Anchor>
      </Content>
    </Tile>
  );
};

const Notice: FunctionComponent = () => {
  const {current} = useCity();
  const {data} = useQuery<
    HandsRuGetHolidayNoticeData,
    HandsRuGetHolidayNoticeVariables
  >(handsRuGetHolidyNotice, {
    variables: {
      cityId: current.id,
    },
    skip: !current.id,
    ssr: false,
  });

  const [holidayNotice] =
    (data?.cities?.length &&
      data.cities[0].components?.filter(c => !!c.content)) ||
    [];

  if (holidayNotice?.content?.html) {
    return (
      <div className="notice notice_holiday">
        <Content extraClassName="notice__content notice__content_holiday">
          <HTMLWrapper>{holidayNotice.content.html}</HTMLWrapper>
        </Content>
      </div>
    );
  }

  return <UnderConstructionNotice />;
};

export default Notice;
