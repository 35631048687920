import {useCallback} from 'react';
import useLocalStorage from '@pollex/hooks/use-local-storage';
import {StringSearchResultType} from 'client/types/search';

const MAX_LENGTH = 3;

const toStringSearchResultType = (value: string): StringSearchResultType => {
  return {
    type: 'StringSearchResultType',
    suggest: value,
  } as StringSearchResultType;
};

export function useLastQueries(): [
  StringSearchResultType[],
  (newQuery: string) => void,
  (queryToDelete: string) => void,
  () => void,
] {
  const [items, persist] = useLocalStorage<string[]>('last-search-queries');
  const list = Array.isArray(items) ? items : [];

  const add = useCallback(
    (query: string) => {
      const nextList = [
        query,
        ...list.filter(suggest => suggest !== query),
      ].slice(0, MAX_LENGTH);

      persist(nextList);
    },
    [list, persist],
  );

  const remove = useCallback(
    (query: string) => {
      persist(list.filter(suggest => suggest !== query));
    },
    [list],
  );

  const clear = useCallback(() => {
    persist([]);
  }, []);

  return [list.map(toStringSearchResultType), add, remove, clear];
}
