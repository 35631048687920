import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const Plus: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  color,
  viewBox,
}) => {
  return (
    <svg
      style={style}
      width={width || '46'}
      height={height || '46'}
      viewBox={viewBox || '0 0 46 46'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={extraClassName}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 20v6H26v20h-6V26H0v-6h20V0h6v20h20z"
        fill={color}
      />
    </svg>
  );
};

export default Plus;
