import React, {FunctionComponent} from 'react';

import cx from 'classnames';
import Placeholder from 'client/components/Placeholder';
import './PlaceholderProgress.css';

interface OwnProps {
  extraClassName?: string;
}

const PlaceholderProgress: FunctionComponent<OwnProps> = ({
  extraClassName,
  ...restOfProps
}) => (
  <div
    className={cx('placeholder-progress', extraClassName)}
    {...restOfProps}
    data-shmid="progress"
  >
    <Placeholder animated randomWidth height="20px" />
    <Placeholder animated randomWidth height="20px" />
    <Placeholder animated randomWidth height="20px" />
  </div>
);

export default PlaceholderProgress;
