import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const Close: FunctionComponent<IconProps> = ({
  width = '22',
  height = '22',
  extraClassName,
  style,
  color,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 22 22"
      className={extraClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.933.5 0 4l8.8 7L0 19l2.933 3 7.823-8.5 8.31 8.5L22 19l-8.8-8L22 3l-2.933-3L11 9 2.933.5Z"
        fill={color}
      />
    </svg>
  );
};

export default Close;
