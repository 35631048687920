import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const ArrowTailed3: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  color,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '21'}
      height={height || '8'}
      fill="none"
      className={extraClassName}
    >
      <path
        fill={color}
        d="M20.4 4.4c.1-.2.1-.6 0-.8L17.2.5a.5.5 0 1 0-.7.7L19.3 4l-2.8 2.8a.5.5 0 1 0 .7.7l3.2-3.1ZM0 4.5h20v-1H0v1Z"
      />
    </svg>
  );
};

export default ArrowTailed3;
