import {useRef, useEffect, useState} from 'react';

type PortalFunction = (
  parentElem: Element | null,
  portalCurrent: Element | void,
) => void;

interface PortalArgs {
  id: string;
  onMount: PortalFunction;
  onUnmount: PortalFunction;
}

export default function usePortal({
  id,
  onMount,
  onUnmount,
}: PortalArgs): Element | void {
  const portal = useRef<Element>();
  // i dont understand why, but this useState shouldnt be removed
  const [, setIsCreated] = useState(false);

  useEffect(() => {
    portal.current = document.createElement('div');

    const parentElem = document.getElementById(id);

    if (parentElem) {
      parentElem.appendChild(portal.current);
    } else {
      const parent = document.createElement('div');
      parent.id = id;
      parent.append(portal.current);
      document.body.append(parent);
    }

    setIsCreated(true);

    onMount(parentElem, portal.current);

    return () => {
      (portal.current as Element).remove();
      onUnmount(parentElem, portal.current);
    };
  }, []);

  return portal.current;
}
