import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const StarEcho: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  color,
  viewBox,
}) => {
  return (
    <svg
      style={style}
      width={width || '142'}
      height={height || '96'}
      viewBox={viewBox || '0 0 142 96'}
      fill="none"
      className={extraClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.359 62.0193L139.855 61.3594L142 63.7066L127.788 74.81L134.288 94.6432L132.859 95.9998L114.899 83.5172L98.1854 95.1112L96.2574 93.4957L102.328 74.8053L87.5068 65.7888L88.7554 63.3469L106.832 62.5716L113.952 43.8985L113.957 43.5554L114.068 43.5946L114.083 43.5557L114.096 43.6044L116.883 44.5873L123.359 62.0193Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.359 62.0193L139.855 61.3594L142 63.7066L127.788 74.81L134.288 94.6432L132.859 95.9998L114.899 83.5172L98.1854 95.1112L96.2574 93.4957L102.328 74.8053L87.5068 65.7888L88.7554 63.3469L106.832 62.5716L113.952 43.8985L113.957 43.5554L114.068 43.5946L114.083 43.5557L114.096 43.6044L116.883 44.5873L123.359 62.0193Z"
        fill={color}
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.514 56.8359L128.587 56.0752L131.067 58.7805L114.634 71.5776L122.15 94.4363L120.498 95.9998L99.9587 81.7706L80.8138 95.1112L78.5967 93.245L85.5783 71.6543L68.5334 61.2388L69.9694 58.4179L90.7579 57.5223L98.6253 36.7964L98.6427 35.5554L99.0429 35.6961L99.0963 35.5557L99.1431 35.7313L102.026 36.7447L109.514 56.8359Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.514 56.8359L128.587 56.0752L131.067 58.7805L114.634 71.5776L122.15 94.4363L120.498 95.9998L99.9587 81.7706L80.8138 95.1112L78.5967 93.245L85.5783 71.6543L68.5334 61.2388L69.9694 58.4179L90.7579 57.5223L98.6253 36.7964L98.6427 35.5554L99.0429 35.6961L99.0963 35.5557L99.1431 35.7313L102.026 36.7447L109.514 56.8359Z"
        fill={color}
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.0934 53.0339L52.0453 54.0364L50.4532 57.1941L69.3508 68.8533L61.6103 93.0219L64.0684 95.1109L85.5355 80.0078L108.422 95.9998L110.253 94.2523L101.925 68.7045L120.134 54.4018L117.386 51.3783L96.2507 52.2284L87.9535 29.7735L84.3519 28.4967L84.3381 28.4443L84.3224 28.4862L84.2043 28.4443L84.1992 28.8139L75.0934 53.0339Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.0934 53.0339L52.0453 54.0364L50.4532 57.1941L69.3508 68.8533L61.6103 93.0219L64.0684 95.1109L85.5355 80.0078L108.422 95.9998L110.253 94.2523L101.925 68.7045L120.134 54.4018L117.386 51.3783L96.2507 52.2284L87.9535 29.7735L84.3519 28.4967L84.3381 28.4443L84.3224 28.4862L84.2043 28.4443L84.1992 28.8139L75.0934 53.0339Z"
        fill={color}
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.3049 46.3017L30.6413 47.4646L28.7996 51.1276L50.6615 64.6522L41.7068 92.6878L44.5505 95.1111L69.5387 77.483L95.7744 96L97.8734 93.9766L88.3261 64.3948L109.2 47.8339L106.05 44.3329L81.8218 45.3172L72.3104 19.3168L68.0125 17.7778L68.0119 17.824L67.9997 17.7778L57.3049 46.3017Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.3049 46.3017L30.6413 47.4646L28.7996 51.1276L50.6615 64.6522L41.7068 92.6878L44.5505 95.1111L69.5387 77.483L95.7744 96L97.8734 93.9766L88.3261 64.3948L109.2 47.8339L106.05 44.3329L81.8218 45.3172L72.3104 19.3168L68.0125 17.7778L68.0119 17.824L67.9997 17.7778L57.3049 46.3017Z"
        fill={color}
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.7861 35.0811L2.24756 36.5114L0 41.0164L26.6789 57.6501L15.7511 92.1307L19.2215 95.111L50.0511 73.1919L81.9845 95.9999L84.5301 93.5166L72.9515 57.2118L98.2665 36.887L94.4469 32.5903L65.0632 33.7984L53.5282 1.88879L48.3158 0L48.2922 1.73938L47.8374 0L34.7861 35.0811Z"
        fill={color}
      />
    </svg>
  );
};

export default StarEcho;
