import React, {FunctionComponent} from 'react';

import cx from 'classnames';
import Anchor from '@pollex/components/anchor';
import Link from '@pollex/components/link';
import {useAnalytics} from 'client/contexts/Analytics';

import './Link.scss';

interface OwnProps {
  href: string;
  external?: boolean;
  themed?: boolean;
}

const FooterLink: FunctionComponent<OwnProps> = ({
  themed,
  external,
  children,
  href,
}) => {
  const {handleEvent} = useAnalytics();
  return (
    <div>
      <Anchor
        component={Link}
        extraClassName={cx('footer-link', {'footer-link_non_themed': !themed})}
        href={href}
        onClick={handleEvent('footer_link_click', {href})}
        external={external}
      >
        {children}
      </Anchor>
    </div>
  );
};

export default FooterLink;
