import React from 'react';
import {matchPath} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import handsRuGetCatalogCategoryPage, {
  HandsRuGetCatalogCategoryPageData,
} from 'query-wrappers/HandsRuGetCatalogCategoryPage';
import handsRuGetCatalogObjectPage, {
  HandsRuGetCatalogObjectPageData,
} from 'query-wrappers/HandsRuGetCatalogObjectPage';
import {useLocation} from 'client/contexts/Location';
import ObjectHub from 'client/components//ObjectHub';
import {toPriceList} from 'client/utils/urls';

export const useObjectCategoryData = () => {
  const location = useLocation();
  const {
    location: {pathname},
    query: locationQuery,
  } = location;

  const matchCategory = matchPath<{slug: string}>(pathname, {
    path: '/category/:slug/',
    exact: true,
  });
  const matchObject = matchPath<{slug: string}>(pathname, {
    path: '/object/:slug/',
    exact: true,
  });
  const dataType: 'CATEGORY' | 'OBJECT' = matchCategory ? 'CATEGORY' : 'OBJECT';
  const slug = matchCategory?.params.slug || matchObject?.params.slug;

  const [query, dataGetter] =
    dataType === 'CATEGORY'
      ? [
          handsRuGetCatalogCategoryPage,
          (data: HandsRuGetCatalogCategoryPageData) => {
            const {
              h1,
              frequentlyAskedQuestions,
              objectPages: pages,
              nameTo,
            } = data.catalog.categoryPage;
            const {priceList} = data.catalog;
            return {
              categorySlug: slug,
              nameTo,
              name: h1,
              answers: frequentlyAskedQuestions,
              priceBlock: pages.map(objectPage => (
                <ObjectHub key={objectPage.slug} {...objectPage} />
              )),
              priceListLink: priceList
                ? toPriceList(priceList.slug, 'category')
                : undefined,
            };
          },
        ]
      : [
          handsRuGetCatalogObjectPage,
          (data: HandsRuGetCatalogObjectPageData) => {
            const {
              frequentlyAskedQuestions,
              nameTo,
              h1,
              object: {
                defaultCategory: {slug: categorySlug},
              },
            } = data.catalog.objectPage;
            const {priceListPage} = data.catalog;
            return {
              categorySlug: locationQuery.category || categorySlug,
              nameTo,
              answers: frequentlyAskedQuestions,
              name: h1,
              priceBlock: (
                <ObjectHub
                  isIndexed={false}
                  slug={slug as string}
                  {...data.catalog.objectPage}
                />
              ),
              priceListLink:
                priceListPage && slug ? toPriceList(slug, 'object') : undefined,
            };
          },
        ];

  const {data} = useQuery(query, {variables: {slug}, skip: !slug});

  if (!data || !slug) {
    return null;
  }

  if (dataType === 'CATEGORY' && !data?.catalog.categoryPage) {
    return null;
  }

  if (dataType === 'OBJECT' && !data?.catalog.objectPage) {
    return null;
  }

  return {
    ...dataGetter(data),
    dataType,
    slug,
  };
};
