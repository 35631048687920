import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const Catalog: FunctionComponent<IconProps> = ({
  width = '18',
  height = '14',
  viewBox = '0 0 18 14',
  color,
  extraClassName,
}) => {
  return (
    <svg
      className={extraClassName}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
    >
      <path fill={color} d="M0 0h18v2H0V0ZM0 6h18v2H0V6ZM0 12h18v2H0v-2Z" />
    </svg>
  );
};

export default Catalog;
