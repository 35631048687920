import React, {FunctionComponent} from 'react';
import {ServiceSearchResultType} from 'client/types/search';
import {highlight} from 'client/components/Search/utils';
import formatNumber from '@pollex/utils/format-number';

import './ServiceSuggestion.scss';

const ServiceSuggestion: FunctionComponent<ServiceSearchResultType> = ({
  service,
  suggestHighlightPositions,
  suggest,
}) => {
  return (
    <div className="service-suggestion">
      <div className="service-suggestion__name">
        {highlight(suggest, suggestHighlightPositions, {
          color: 'var(--ruki-blue)',
        })}
      </div>
      <div className="service-suggestion__price">
        {service.price?.amount > 0 ? (
          <>
            {formatNumber(service.price.amount)} {service.price.unitMorphology}
          </>
        ) : (
          <>
            от {formatNumber(service.minimumPrice.amount)}{' '}
            {service.minimumPrice.unitMorphology}
          </>
        )}
      </div>
    </div>
  );
};

export default ServiceSuggestion;
