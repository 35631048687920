import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const Trash: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
}) => {
  return (
    <svg
      className={extraClassName}
      xmlns="http://www.w3.org/2000/svg"
      width={width || '32'}
      height={height || '32'}
      fill="none"
      viewBox="0 0 32 32"
    >
      <mask
        id="trash"
        width="32"
        height="32"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" stroke="#DADADA" d="M.5.5h31v31H.5z" />
      </mask>
      <g stroke="#DADADA" mask="url(#trash)">
        <path strokeWidth="2" d="M9.22 25 8.07 8h15.86l-1.15 17H9.22Z" />
        <path d="M15.5 3.5h1v4.333h-1z" />
        <path
          fill="#FF2FF2"
          d="M5.5 7.5h21v1h-21zM13.166 12.5h1v8.333h-1zM17.834 12.5h1v8.333h-1z"
        />
      </g>
    </svg>
  );
};

export default Trash;
