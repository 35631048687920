import React, {FunctionComponent, Ref} from 'react';

import cx from 'classnames';
import './SearchMenu.scss';

interface OwnProps {
  extraClassName?: string;
  innerRef?: Ref<HTMLDivElement>;
}

const SearchMenu: FunctionComponent<OwnProps> = ({
  children,
  extraClassName,
  innerRef,
  ...restOfProps
}) => (
  <div
    ref={innerRef}
    className={cx('search-menu', extraClassName)}
    {...restOfProps}
  >
    {children}
  </div>
);

export default SearchMenu;
