/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {ForwardRefRenderFunction, MouseEventHandler} from 'react';
import cx from 'classnames';
import Icon from '@pollex/components/icon/components/Arrow';
import './SearchOption.scss';

interface OwnProps {
  extraClassName?: string;
  readOnly?: boolean;
  onClick?: MouseEventHandler;
  isActive?: boolean;
  isSelected?: boolean;
  customIcon?: JSX.Element;
  children: any;
}

const SearchOption: ForwardRefRenderFunction<HTMLDivElement, OwnProps> = (
  {
    children,
    extraClassName,
    isActive,
    isSelected,
    readOnly,
    onClick,
    customIcon,
    ...restOfProps
  },
  ref,
) => (
  <div
    ref={ref}
    className={cx('search-option', extraClassName, {
      'search-option_highlighted': !readOnly && isActive,
      'search-option_selected': !readOnly && isSelected,
    })}
    {...restOfProps}
    onMouseDown={e => {
      if (onClick) onClick(e);
    }}
  >
    {!readOnly && (
      <div className="search-option__arrow">
        {customIcon || (
          <Icon
            height="15px"
            width="15px"
            viewBox="0 0 15 15"
            color="rgba(0, 0, 0, 0.3)"
            extraClassName="search-option__arrow-core"
          />
        )}
      </div>
    )}
    {children}
  </div>
);

export default React.forwardRef(SearchOption);
