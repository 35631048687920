import React, {FunctionComponent} from 'react';

import Anchor from '@pollex/components/anchor';
import Link from '@pollex/components/link';
import {useLocation} from 'client/contexts/Location';
import {useAnalytics} from 'client/contexts/Analytics';
import Price from 'client/ui/Price';
import Hub from 'client/components/Hub';
import {toObject, toService} from 'client/utils/urls';
import {ServicePageSlugType, ObjectPageSlugType} from 'client/types/slug';
import {PriceType} from 'client/types/price';
import typographize from '@pollex/utils/typographize';
import {useParams} from 'react-router-dom';
import './ObjectHub.scss';

interface OwnProps {
  name?: string;
  isIndexed: boolean;
  object: {
    name: string;
  };
  slug: ObjectPageSlugType;
  mainServicePages: {
    slug: ServicePageSlugType;
    name: string;
    service: {
      price: PriceType;
      minimumPrice: PriceType;
    };
  }[];
  satelliteServicePages: {
    slug: ServicePageSlugType;
    name: string;
    service: {
      price: PriceType;
      minimumPrice: PriceType;
    };
  }[];
}

const ObjectHub: FunctionComponent<OwnProps> = ({
  name,
  slug,
  isIndexed,
  object,
  mainServicePages,
  satelliteServicePages,
}: OwnProps) => {
  const {category: categorySlug} = useParams<{
    category: string;
  }>();
  const location = useLocation();
  const {handleEvent} = useAnalytics();

  if ([...mainServicePages, ...satelliteServicePages].length === 0) {
    return null;
  }

  return (
    <Hub
      extraClassName="object-hub"
      data-shmid="object-title"
      title={{
        label: typographize(name || object.name),
        onClickCapture: handleEvent('object_page_link_click', {slug}),
        extraClassName: 'object-hub__title-link',
        ...(isIndexed && {
          href: toObject({slug}, {slug: categorySlug}).serialize(location),
        }),
      }}
      links={[...mainServicePages, ...satelliteServicePages].map(
        ({service, ...servicePage}) => ({
          href: toService(servicePage, {
            category: {slug: categorySlug},
          }).serialize(location),

          onClickCapture: handleEvent('service_page_link_click', {
            slug: servicePage.slug,
          }),

          label: (
            <>
              <Anchor
                extraClassName="object-hub__label"
                href={toService(servicePage, {
                  category: {slug: categorySlug},
                }).serialize(location)}
                component={Link}
              >
                {typographize(servicePage.name)}
              </Anchor>

              <span className="object-hub__price">
                &nbsp;от{' '}
                <Price
                  component="span"
                  amount={service.price.amount || service.minimumPrice.amount}
                  unit={service.price.unit || service.minimumPrice.unit}
                />
              </span>
            </>
          ),
          extraClassName: 'object-hub__link',
        }),
      )}
    />
  );
};

export default ObjectHub;
