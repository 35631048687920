import React, {FunctionComponent} from 'react';
import parse, {domToReact, DomElement} from 'html-react-parser';
import cx from 'classnames';
import typographize from '@pollex/utils/typographize';
import {ReplaceNodeFunction} from 'client/types/html';
import StaticLayoutTitle from '../StaticLayoutTitle';
import StaticLayoutBlock from '../StaticLayoutBlock';
import StaticLayoutList from '../StaticLayoutList';
import StaticLayoutLead from '../StaticLayoutLead';

import './StaticLayoutText.scss';

interface OwnProps {
  component?: any;
  html?: boolean;
  paragraph?: boolean;
  extraClassName?: string;
  style?: Record<string, any>;
  replaceText?: ReplaceNodeFunction;
  size?: 'medium' | 'small' | 'xsmall' | 'uppercase';
  weight?: 'bold';
}

function tagToTitle(name: string): 2 | 3 | void {
  return {h1: 2, h2: 2, h3: 3}[name] as 2 | 3 | void;
}

const StaticLayoutText: FunctionComponent<OwnProps> = ({
  component: Component = 'div',
  children,
  paragraph,
  html,
  extraClassName,
  replaceText,
  size,
  weight,
  ...restOfProps
}) => (
  <Component
    className={cx(
      'static-layout-text',
      {'static-layout-text_paragraph': paragraph},
      {'static-layout-text_small': size === 'small'},
      {'static-layout-text_xsmall': size === 'xsmall'},
      {'static-layout-text_uppercase': size === 'uppercase'},
      {'static-layout-text_bold': weight === 'bold'},
      extraClassName,
    )}
    {...restOfProps}
  >
    {html ? parse(children as string, parserOptions(replaceText)) : children}
  </Component>
);

const parserOptions = (replaceText: ReplaceNodeFunction | undefined) => ({
  replace(node: DomElement) {
    const {type} = node;

    if (type === 'text') {
      const text = node.data;
      return typographize(text) as any;
    }

    if (type !== 'tag') return;

    const {name, children: subchildren, attribs} = node;

    if (name === 'ul') {
      const withoutDecorations =
        attribs?.style && attribs?.style.includes('list-style-type');

      return (
        <StaticLayoutList withDecorations={!withoutDecorations}>
          {domToReact(subchildren || [], parserOptions(replaceText))}
        </StaticLayoutList>
      );
    }

    if (name === 'ol') {
      return (
        <StaticLayoutList ordered>
          {domToReact(subchildren || [], parserOptions(replaceText))}
        </StaticLayoutList>
      );
    }

    if (name === 'li') {
      return (
        <StaticLayoutList.Item>
          {domToReact(subchildren || [], parserOptions(replaceText))}
        </StaticLayoutList.Item>
      );
    }

    if (name === 'span') {
      return <>{domToReact(subchildren || [], parserOptions(replaceText))}</>;
    }

    if (name === 'p') {
      const nonEmpty = (subchildren || []).filter(
        s => s.type !== 'text' || (s.data && s.data.match(/[а-я]/i)),
      );

      if (!nonEmpty.length) return <></>;

      return (
        <StaticLayoutText
          extraClassName="static-layout-text_paragraph"
          component="p"
        >
          {domToReact(nonEmpty, parserOptions(replaceText))}
        </StaticLayoutText>
      );
    }

    const titleLevel = tagToTitle(name || '');

    if (titleLevel === 2) {
      return (
        <StaticLayoutBlock extraClassName="static-layout-text_h2-container">
          <StaticLayoutTitle level={2}>
            {domToReact(subchildren || [], parserOptions(replaceText))}
          </StaticLayoutTitle>
        </StaticLayoutBlock>
      );
    }

    if (titleLevel === 3) {
      return (
        <StaticLayoutLead
          component="h3"
          extraClassName="static-layout-text_h3-container"
        >
          {domToReact(subchildren || [], parserOptions(replaceText))}
        </StaticLayoutLead>
      );
    }

    if (replaceText) {
      return replaceText(node);
    }
  },
});

export default StaticLayoutText;
