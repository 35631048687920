import React, {FunctionComponent} from 'react';
import {IconProps} from '../../types';

const MinusHollow: FunctionComponent<IconProps> = ({
  width,
  height,
  extraClassName,
  style,
  color,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      className={extraClassName}
      fill="none"
      viewBox="0 0 46 46"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M45.5 20.5v5H.5v-5h45z" stroke={color} strokeOpacity=".1" />
    </svg>
  );
};

export default MinusHollow;
